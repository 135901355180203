import { Stack, Typography } from '@mui/material';
import {
  dayjs,
  formatAsPercentage,
  getDateFormatted,
  getAvg,
  isBloomberg,
  nextBusinessDay,
  nullsToEndComparator,
  valOrNa,
  prevBusinessDayOpenMarket,
} from '../../../util';
import { Theme, alpha } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import { formatAsCompactNumber } from '../../../util';
import * as d3 from 'd3';
import {
  GridColDef,
  GridColumnGroup,
  GridColumnGroupHeaderParams,
  GridColumnHeaderParams,
  gridDateComparator,
  gridNumberComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from '@spotgamma/x-data-grid-premium';
import { useRecoilValue } from 'recoil';
import {
  COLUMN_TO_GROUP_MAPPING,
  scannerOnlyFields,
  historyOnlyFields,
  SCANNER_TOOLTIP_MAP,
} from '../../../config';
import {
  isMobileState,
  timezoneState,
  positiveTrendColorState,
  negativeTrendColorState,
} from '../../../states';
import { EquityFieldKey } from '../../../types';
import { SGTooltip } from '../../core';
import RowWatchlistBtn from '../../stock_scanner/RowWatchlistBtn';
import StockIcon from '../../StockIcon';

export const enum EquityTableType {
  History,
  StockScreener,
}

export const useEquityGridColumns = (
  theme: Theme,
  dpiRange: number[] | undefined,
  tableType: EquityTableType,
  blurredRowIds?: string[],
) => {
  const isMobile = useRecoilValue(isMobileState);
  const currentTimezone = useRecoilValue(timezoneState);

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  const dpiColorScale = useMemo(
    () => dpiRange && d3.scaleSequential(d3.interpolateRdYlGn).domain(dpiRange),
    [dpiRange],
  );

  const history = tableType === EquityTableType.History;

  const defaultHeaderTitleStyles = {
    fontSize: isMobile ? 11 : 13,
    color: theme.palette.sgGreen,
    textTransform: 'capitalize',
    textAlign: 'right',
    whiteSpace: 'normal',
    lineHeight: 'normal',
  };

  const getColHeaderStyles = useCallback(
    (params: GridColumnHeaderParams) => ({
      ...defaultHeaderTitleStyles,
      color: COLUMN_TO_GROUP_MAPPING[
        params.field as keyof typeof COLUMN_TO_GROUP_MAPPING
      ]
        ? alpha(
            theme.palette.equityHubColumns[
              COLUMN_TO_GROUP_MAPPING[
                params.field as keyof typeof COLUMN_TO_GROUP_MAPPING
              ]
            ],
            0.85,
          )
        : 'inherit',
    }),
    [defaultHeaderTitleStyles, theme.palette.equityHubColumns],
  );

  const getHistoryTextColor = useCallback(
    (params: GridRenderCellParams, field: EquityFieldKey) => {
      if (!history) {
        return 'inherit';
      }

      // Assuming id is in the YYYY-MM-DD format since historical table uses formatted date ids
      const currentDayKey = params.id;
      const previousDayKey = getDateFormatted(
        prevBusinessDayOpenMarket(dayjs(currentDayKey)),
      );

      // Accessing the previous day's row
      const previousDayRow = params.api.getRow(previousDayKey);

      if (!previousDayRow || params.value === previousDayRow?.[field]) {
        return 'inherit';
      }

      return params.value < previousDayRow?.[field]
        ? serverNegativeTrendColor
        : serverPositiveTrendColor;
    },
    [history, serverNegativeTrendColor, serverPositiveTrendColor],
  );

  const getColoredCell = useCallback(
    (params: GridRenderCellParams) => (
      <Typography
        sx={{
          color: getHistoryTextColor(params, params.field as EquityFieldKey),
          ...getBlurredStyles(params),
        }}
      >
        {valOrNa(params.value)}
      </Typography>
    ),
    [getHistoryTextColor],
  );

  const columnGroups: GridColumnGroup[] = history
    ? []
    : [
        {
          groupId: 'general_ticker_info',
          headerName: 'Ticker Information',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: EquityFieldKey.price },
            { field: EquityFieldKey.prevClose },
            { field: EquityFieldKey.volume },
            { field: EquityFieldKey.wkHigh52 },
            { field: EquityFieldKey.wkLow52 },
            { field: EquityFieldKey.earningsDate },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.STATISTICS,
              }}
            >
              Ticker Information
            </Typography>
          ),
        },
        {
          groupId: 'sg_key_daily_levels',
          headerName: 'SpotGamma Key Daily Levels',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: EquityFieldKey.keyGammaStr },
            { field: EquityFieldKey.keyDeltaStr },
            { field: EquityFieldKey.hedgeWall },
            { field: EquityFieldKey.callWall },
            { field: EquityFieldKey.putWall },
            { field: EquityFieldKey.callGamma },
            { field: EquityFieldKey.putGamma },
            { field: EquityFieldKey.callDelta },
            { field: EquityFieldKey.putDelta },
            { field: EquityFieldKey.nextExpGamma },
            { field: EquityFieldKey.nextExpDelta },
            { field: EquityFieldKey.topGammaExp },
            { field: EquityFieldKey.topDeltaExp },
            { field: EquityFieldKey.callVol },
            { field: EquityFieldKey.putVol },
            { field: EquityFieldKey.nextExpCallVol },
            { field: EquityFieldKey.nextExpPutVol },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.SG_KEY_DAILY_LEVELS,
              }}
            >
              SpotGamma Key Daily Levels
            </Typography>
          ),
        },
        {
          groupId: 'directional_indicators',
          headerName: 'Directional Indicators',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: EquityFieldKey.putCallVolRatio },
            { field: EquityFieldKey.gammaRatio },
            { field: EquityFieldKey.deltaRatio },
            { field: EquityFieldKey.putCallRatio },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.DIRECTIONAL_INDICATORS,
              }}
            >
              Directional Indicators
            </Typography>
          ),
        },
        {
          groupId: 'vol_insights',
          headerName: 'Volatility Insights',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: EquityFieldKey.neSkew },
            { field: EquityFieldKey.skew },
            { field: EquityFieldKey.rv30 },
            { field: EquityFieldKey.iv30 },
            { field: EquityFieldKey.ivRank },
            { field: EquityFieldKey.garchRank },
            { field: EquityFieldKey.skewRank },
            { field: EquityFieldKey.optionsImpliedMove },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.VOLATILITY_INSIGHTS,
              }}
            >
              Volatility Insights
            </Typography>
          ),
        },
        {
          groupId: 'dp_indicators',
          headerName: 'Dark Pool Indicators',
          description: '',
          headerClassName: 'custom-group-header',
          freeReordering: true,
          children: [
            { field: EquityFieldKey.dpi },
            { field: EquityFieldKey.dpiVol },
            { field: EquityFieldKey.dpi5day },
            { field: EquityFieldKey.dpi5dayVol },
          ],
          renderHeaderGroup: (_params: GridColumnGroupHeaderParams) => (
            <Typography
              sx={{
                ...defaultHeaderTitleStyles,
                fontWeight: 'bold',
                color: theme.palette.equityHubColumns.DARK_POOL_INDICATORS,
              }}
            >
              Dark Pool Indicators
            </Typography>
          ),
        },
      ];

  const getBlurredStyles = (params: GridRenderCellParams) =>
    blurredRowIds?.includes(params.id as string) ? { filter: 'blur(3px)' } : {};

  const columns: GridColDef[] = useMemo(
    () =>
      (
        [
          {
            headerName: 'Trade Date',
            field: EquityFieldKey.tradeDate,
            headerClassName: 'grid-header-cell',
            minWidth: 110,
            type: 'date',
            hideable: false,
            getSortComparator: nullsToEndComparator(gridDateComparator),
            valueGetter: (value: string) => {
              return value && dayjs.utc(value).toDate();
            },
            valueFormatter: (value: Date) =>
              `${valOrNa(
                value != null &&
                  getDateFormatted(nextBusinessDay(dayjs(value).utc())),
              )}`,
            renderCell: (params: GridRenderCellParams) => {
              const date = dayjs(params.value).utc();
              return (
                <Typography
                  sx={{
                    whiteSpace: 'normal',
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    date != null && getDateFormatted(nextBusinessDay(date)),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <Typography
                sx={{
                  ...getColHeaderStyles(params),
                }}
              >
                Trade Date
              </Typography>
            ),
          },
          ...(!isBloomberg()
            ? [
                {
                  headerName: '',
                  type: 'boolean',
                  field: 'isWatchlisted',
                  headerClassName: 'grid-header-cell',
                  width: 45,
                  hideable: false,
                  filterable: false,
                  disableColumnMenu: true,
                  valueFormatter: (value: boolean) => `${valOrNa(value)}`,
                  renderCell: RowWatchlistBtn,
                },
              ]
            : []),
          {
            headerName: 'Symbol',
            field: EquityFieldKey.sym,
            headerClassName: 'grid-header-cell',
            minWidth: 110,
            hideable: false,
            getSortComparator: nullsToEndComparator(
              gridStringOrNumberComparator,
            ),
            valueFormatter: (value: string) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Stack justifyContent="center">
                  <Stack
                    direction="row"
                    gap={2}
                    alignItems="center"
                    sx={{
                      ...getBlurredStyles(params),
                    }}
                  >
                    <StockIcon
                      symbol={params.value}
                      sx={{ width: 22, height: 22 }}
                    />
                    <Typography>{valOrNa(params.value)}</Typography>
                  </Stack>
                </Stack>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.sym)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Symbol
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Current Price',
            field: EquityFieldKey.price,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              if (isNaN(params.value)) {
                return null;
              }

              return (
                <Typography
                  color={
                    params.value > params.row.upx
                      ? theme.palette.success.main
                      : 'red'
                  }
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.price)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Current Price
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Previous Close',
            field: EquityFieldKey.prevClose,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.prevClose)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Previous Close
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Stock Volume',
            field: EquityFieldKey.volume,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value?.toLocaleString())}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.volume)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Stock Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '52wk High',
            field: EquityFieldKey.wkHigh52,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `$${params.value.toFixed(2)}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.wkHigh52)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  52wk High
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '52wk Low',
            field: EquityFieldKey.wkLow52,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `$${params.value.toFixed(2)}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.wkLow52)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  52wk Low
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Earnings Date',
            field: EquityFieldKey.earningsDate,
            type: 'date',
            headerClassName: 'grid-header-cell',
            getSortComparator: nullsToEndComparator(gridDateComparator),
            minWidth: 135,
            valueGetter: (value: string) => {
              return value && dayjs.utc(value).toDate();
            },
            valueFormatter: (value: Date) =>
              valOrNa(
                value != null &&
                  `${dayjs(value.valueOf())
                    .tz(currentTimezone)
                    .format('MM-DD h:mm A')}`,
              ),
            renderCell: (params: GridRenderCellParams) => {
              const date = params.value;
              return (
                <Typography
                  sx={{
                    whiteSpace: 'normal',
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    date != null &&
                      `${dayjs(date.valueOf())
                        .tz(currentTimezone)
                        .format('MM-DD h:mm A')}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.earningsDate)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Earnings Date
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Key Gamma Strike',
            field: EquityFieldKey.keyGammaStr,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.keyGammaStr)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                    color: alpha(
                      theme.palette.equityHubColumns[
                        COLUMN_TO_GROUP_MAPPING[
                          params.field as keyof typeof COLUMN_TO_GROUP_MAPPING
                        ]
                      ],
                      0.75,
                    ),
                  }}
                >
                  Key Gamma Strike
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Key Delta Strike',
            field: EquityFieldKey.keyDeltaStr,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.keyDeltaStr)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Key Delta Strike
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Hedge Wall',
            field: EquityFieldKey.hedgeWall,
            headerClassName: 'grid-header-cell',
            minWidth: 125,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.hedgeWall)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Hedge Wall
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Call Wall',
            field: EquityFieldKey.callWall,
            headerClassName: 'grid-header-cell',
            minWidth: 112,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.callWall)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Call Wall
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put Wall',
            field: EquityFieldKey.putWall,
            headerClassName: 'grid-header-cell',
            minWidth: 112,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: getColoredCell,
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.putWall)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put Wall
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Call Gamma',
            field: EquityFieldKey.callGamma,
            headerClassName: 'grid-header-cell',
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            minWidth: 132,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                sx={{
                  ...getBlurredStyles(params),
                }}
              >
                {valOrNa(
                  params.value != null && formatAsCompactNumber(params.value),
                )}
              </Typography>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.callGamma)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Call Gamma
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put Gamma',
            field: EquityFieldKey.putGamma,
            headerClassName: 'grid-header-cell',
            minWidth: 132,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsCompactNumber(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.putGamma)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put Gamma
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Call Delta',
            field: EquityFieldKey.callDelta,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsCompactNumber(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.callDelta)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Call Delta
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put Delta',
            field: EquityFieldKey.putDelta,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsCompactNumber(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.putDelta)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put Delta
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Next Expiry Gamma %',
            field: EquityFieldKey.nextExpGamma,
            headerClassName: 'grid-header-cell',
            minWidth: 145,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null &&
                      `${(params.value * 100).toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.nextExpGamma)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Next Expiry Gamma %
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Next Expiry Delta %',
            field: EquityFieldKey.nextExpDelta,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null &&
                      `${(params.value * 100).toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.nextExpDelta)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Next Expiry Delta %
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Top Gamma Expiry',
            field: EquityFieldKey.topGammaExp,
            type: 'date',
            headerClassName: 'grid-header-cell',
            getSortComparator: nullsToEndComparator(gridDateComparator),
            valueFormatter: (value: Date) =>
              `${valOrNa(value != null && getDateFormatted(value))}`,
            minWidth: 135,
            valueGetter: (value: string) => {
              return value && dayjs.utc(value).toDate();
            },
            renderCell: (params: GridRenderCellParams) => {
              const date = params.value;
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(date != null && getDateFormatted(date))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.topGammaExp)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Top Gamma Expiry
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Top Delta Expiry',
            field: EquityFieldKey.topDeltaExp,
            type: 'date',
            headerClassName: 'grid-header-cell',
            getSortComparator: nullsToEndComparator(gridDateComparator),
            valueFormatter: (value: Date) =>
              `${valOrNa(value != null && getDateFormatted(value))}`,
            minWidth: 135,
            valueGetter: (value: string) => {
              return value && dayjs.utc(value).toDate();
            },
            renderCell: (params: GridRenderCellParams) => {
              const date = params.value;
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(date != null && getDateFormatted(date))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.topDeltaExp)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Top Delta Expiry
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Call Volume',
            field: EquityFieldKey.callVol,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                sx={{
                  ...getBlurredStyles(params),
                }}
              >
                {valOrNa(
                  params.value != null && formatAsCompactNumber(params.value),
                )}
              </Typography>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.callVol)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Call Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put Volume',
            field: EquityFieldKey.putVol,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => (
              <Typography
                sx={{
                  ...getBlurredStyles(params),
                }}
              >
                {valOrNa(
                  params.value != null && formatAsCompactNumber(params.value),
                )}
              </Typography>
            ),
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.putVol)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Next Expiry Call Volume',
            field: EquityFieldKey.nextExpCallVol,
            headerClassName: 'grid-header-cell',
            minWidth: 155,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null &&
                      `${(params.value * 100).toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.nextExpCallVol)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Next Expiry Call Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Next Expiry Put Volume',
            field: EquityFieldKey.nextExpPutVol,
            headerClassName: 'grid-header-cell',
            minWidth: 155,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null &&
                      `${(params.value * 100).toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.nextExpPutVol)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Next Expiry Put Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put/Call OI\u00a0Ratio',
            field: EquityFieldKey.putCallRatio,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.putCallRatio)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  {'Put/Call OI\u00a0Ratio'}
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Put/Call Volume Ratio',
            field: EquityFieldKey.putCallVolRatio,
            headerClassName: 'grid-header-cell',
            minWidth: 135,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.putCallVolRatio)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Put/Call Volume Ratio
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Gamma Ratio',
            field: EquityFieldKey.gammaRatio,
            headerClassName: 'grid-header-cell',
            minWidth: 132,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.gammaRatio)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Gamma Ratio
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Delta Ratio',
            field: EquityFieldKey.deltaRatio,
            headerClassName: 'grid-header-cell',
            minWidth: 120,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.deltaRatio)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Delta Ratio
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'NE Skew',
            field: EquityFieldKey.neSkew,
            headerClassName: 'grid-header-cell',
            minWidth: 120,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value?.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.neSkew)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  NE Skew
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Skew',
            field: EquityFieldKey.skew,
            headerClassName: 'grid-header-cell',
            minWidth: 118,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(params.value != null && params.value.toFixed(2))}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.skew)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Skew
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '1 M RV',
            field: EquityFieldKey.rv30,
            headerClassName: 'grid-header-cell',
            minWidth: 115,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsPercentage(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.rv30)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  1 M RV
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '1 M IV',
            field: EquityFieldKey.iv30,
            headerClassName: 'grid-header-cell',
            minWidth: 115,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsPercentage(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.iv30)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  1 M IV
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'IV Rank',
            field: EquityFieldKey.ivRank,
            headerClassName: 'grid-header-cell',
            minWidth: 115,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsPercentage(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.ivRank)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  IV Rank
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Garch Rank',
            field: EquityFieldKey.garchRank,
            headerClassName: 'grid-header-cell',
            minWidth: 123,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && formatAsPercentage(params.value),
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.garchRank)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Garch Rank
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Skew Rank',
            minWidth: 123,
            headerClassName: 'grid-header-cell',
            field: EquityFieldKey.skewRank,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `${params.value.toFixed(2)}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.skewRank)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Skew Rank
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'Options Implied Move',
            field: EquityFieldKey.optionsImpliedMove,
            headerClassName: 'grid-header-cell',
            minWidth: 145,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `$${params.value.toFixed(2)}`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(
                  EquityFieldKey.optionsImpliedMove,
                )}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  Options Implied Move
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: 'DPI',
            field: EquityFieldKey.dpi,
            headerClassName: 'grid-header-cell',
            minWidth: 108,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            valueGetter: (value: number) => value && value * 100,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Stack
                  sx={{
                    backgroundColor: dpiColorScale
                      ? dpiColorScale(params.value)
                      : 'transparent',
                    textAlign: 'end',
                    minWidth: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    ...getBlurredStyles(params),
                  }}
                >
                  <Typography
                    color={
                      params.value != null
                        ? theme.palette.grey[900]
                        : theme.palette.text.primary
                    }
                  >
                    {valOrNa(
                      params.value != null && `${params.value.toFixed(2)}%`,
                    )}
                  </Typography>
                </Stack>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.dpi)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  DPI
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '% DPI Volume',
            field: EquityFieldKey.dpiVol,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(
                    params.value != null && `${params.value.toFixed(2)}%`,
                  )}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.dpiVol)}>
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  % DPI Volume
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '5 day DPI',
            field: EquityFieldKey.dpi5day,
            headerClassName: 'grid-header-cell',
            minWidth: 125,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            valueGetter: (value: string) => {
              if (!value) {
                return null;
              }

              const hist_vals = value.split(',');

              return getAvg(
                hist_vals
                  .slice(0, Math.min(5, hist_vals.length))
                  .map((v: string) => parseFloat(v)),
              );
            },
            renderCell: (params: GridRenderCellParams) => {
              const val = params.value;
              let bg = 'transparent';

              if (params.value != null) {
                bg = dpiColorScale ? dpiColorScale(val!) : 'transparent';
              }

              return (
                <Stack
                  sx={{
                    backgroundColor: bg,
                    minWidth: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    ...getBlurredStyles(params),
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: bg,
                      textAlign: 'end',
                    }}
                    color={
                      val != null
                        ? theme.palette.grey[900]
                        : theme.palette.text.primary
                    }
                  >
                    {valOrNa(val != null && `${(val * 100).toFixed(2)}%`)}
                  </Typography>
                </Stack>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.dpi5day)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  5 day DPI
                </Typography>
              </SGTooltip>
            ),
          },
          {
            headerName: '5d % DPI Volume',
            field: EquityFieldKey.dpi5dayVol,
            headerClassName: 'grid-header-cell',
            minWidth: 130,
            type: 'number',
            getSortComparator: nullsToEndComparator(gridNumberComparator),
            valueFormatter: (value: number) => `${valOrNa(value)}`,
            valueGetter: (value: string) => {
              if (!value) {
                return null;
              }

              const hist_vals = value.split(',');

              return getAvg(
                hist_vals
                  .slice(0, Math.min(5, hist_vals.length))
                  .map((v: string) => parseFloat(v)),
              );
            },
            renderCell: (params: GridRenderCellParams) => {
              const val: number | null = params.value;

              return (
                <Typography
                  sx={{
                    ...getBlurredStyles(params),
                  }}
                >
                  {valOrNa(val != null && `${val.toFixed(2)}%`)}
                </Typography>
              );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
              <SGTooltip
                title={SCANNER_TOOLTIP_MAP.get(EquityFieldKey.dpi5dayVol)}
              >
                <Typography
                  sx={{
                    ...getColHeaderStyles(params),
                  }}
                >
                  5d % DPI Volume
                </Typography>
              </SGTooltip>
            ),
          },
        ] as GridColDef[]
      ).filter((colDef: GridColDef) =>
        history
          ? !scannerOnlyFields.includes(colDef.field as EquityFieldKey)
          : !historyOnlyFields.includes(colDef.field as EquityFieldKey),
      ) as GridColDef[],
    [
      currentTimezone,
      dpiColorScale,
      getColHeaderStyles,
      getColoredCell,
      history,
      theme.palette.equityHubColumns,
      theme.palette.grey,
      theme.palette.success.main,
      theme.palette.text.primary,
    ],
  );

  return {
    columns,
    columnGroups,
  };
};
