import { useMemo } from 'react';
import {
  CompassSymbolDataKey,
  convertAxisToDataKey,
  StrategyCompassMode,
  StrategyCompassModeXMap,
  StrategyCompassModeYMap,
  StrategyCompassModeZMap,
  StrategyCompassXYZAxis,
} from '../../types/compass';
import { isValidCompassSymbol } from '../../util/compass';
import { useRecoilState, useRecoilValue } from 'recoil';
import { watchlistsState } from '../../states';
import { selectedWatchlistsSymbols } from '../../util/shared/watchlists';
import {
  compassActiveWatchlistsIdsState,
  compassActiveModeState,
  compassActiveSymbolsState,
  compassActiveXAxisState,
  compassActiveYAxisState,
  compassActiveZAxisState,
} from '../../states/compass';

export type CompassParams = {
  xAxis: StrategyCompassXYZAxis;
  setXAxis: (axis: StrategyCompassXYZAxis) => void;
  yAxis: StrategyCompassXYZAxis;
  setYAxis: (axis: StrategyCompassXYZAxis) => void;
  zAxis: StrategyCompassXYZAxis | undefined;
  setZAxis: (axis: StrategyCompassXYZAxis) => void;
  xAxisDataKey: CompassSymbolDataKey;
  yAxisDataKey: CompassSymbolDataKey;
  zAxisDataKey: CompassSymbolDataKey | undefined;
  mode: StrategyCompassMode;
  setMode: (mode: StrategyCompassMode) => void;
  syms: string[];
  setSyms: (syms: string[]) => void;
  editable: boolean;
  setActiveWatchlistIds: (activeWatchlistIds: number[]) => void;
  setActiveWatchlistIdsState: (activeWatchlistIds: number[]) => void;
  activeWatchlistIds: number[];
};

export const useCompassParams = (): CompassParams => {
  const [activeWatchlistIds, setActiveWatchlistIdsState] = useRecoilState(
    compassActiveWatchlistsIdsState,
  );

  const [mode, setModeState] = useRecoilState(compassActiveModeState);
  const [syms, setSymsState] = useRecoilState(compassActiveSymbolsState);

  const [xAxisState, setXAxisState] = useRecoilState(compassActiveXAxisState);
  const [yAxisState, setYAxisState] = useRecoilState(compassActiveYAxisState);
  const [zAxisState, setZAxisState] = useRecoilState(compassActiveZAxisState);

  const watchlists = useRecoilValue(watchlistsState);

  const xAxis = useMemo(() => {
    if (mode === StrategyCompassMode.Compass) {
      return StrategyCompassXYZAxis.RRPercentile; // force x-axis to be Risk Reversal Percentile
    }

    const acceptableX = StrategyCompassModeXMap.get(mode)!;

    // Use stored value if it's valid for the current mode
    if (xAxisState && acceptableX.includes(xAxisState)) {
      return xAxisState;
    }

    return acceptableX[0];
  }, [mode, xAxisState]);

  const yAxis = useMemo(() => {
    if (mode === StrategyCompassMode.Compass) {
      return StrategyCompassXYZAxis.IvRank; // force y-axis to be IV Rank
    }

    const acceptableY = StrategyCompassModeYMap.get(mode)!;

    // Use stored value if it's valid for the current mode
    if (yAxisState && acceptableY.includes(yAxisState)) {
      return yAxisState;
    }

    return acceptableY[0];
  }, [mode, yAxisState]);

  const zAxis = useMemo(() => {
    if (mode === StrategyCompassMode.Compass) {
      return undefined;
    }

    const acceptableZ = StrategyCompassModeZMap.get(mode)!;

    // Use stored value if it's valid for the current mode
    if (zAxisState && acceptableZ.includes(zAxisState)) {
      return zAxisState;
    }

    return undefined;
  }, [mode, zAxisState]);

  const xAxisDataKey = useMemo(() => convertAxisToDataKey(xAxis), [xAxis]);

  const yAxisDataKey = useMemo(() => convertAxisToDataKey(yAxis), [yAxis]);

  const zAxisDataKey = useMemo(
    () =>
      zAxis == null || zAxis === StrategyCompassXYZAxis.None
        ? undefined
        : convertAxisToDataKey(zAxis),
    [zAxis],
  );

  const setXAxis = (newX: StrategyCompassXYZAxis) => {
    const acceptableX = StrategyCompassModeXMap.get(mode)!;
    if (acceptableX.includes(newX)) {
      setXAxisState(newX);
    }
  };

  const setYAxis = (newY: StrategyCompassXYZAxis) => {
    const acceptableY = StrategyCompassModeYMap.get(mode)!;
    if (acceptableY.includes(newY)) {
      setYAxisState(newY);
    }
  };

  const setZAxis = (newZ: StrategyCompassXYZAxis) => {
    const acceptableZ = StrategyCompassModeZMap.get(mode)!;
    if (acceptableZ.includes(newZ)) {
      setZAxisState(newZ);
    }
  };

  const setMode = (newMode: StrategyCompassMode) => {
    setModeState(newMode);

    // Reset axes to defaults for the new mode
    const acceptableX = StrategyCompassModeXMap.get(newMode)!;
    const acceptableY = StrategyCompassModeYMap.get(newMode)!;
    const acceptableZ = StrategyCompassModeZMap.get(newMode)!;

    setXAxisState(acceptableX[0]);
    setYAxisState(acceptableY[0]);
    setZAxisState(
      newMode === StrategyCompassMode.Compass ? undefined : acceptableZ[0],
    );
  };

  const setSyms = (newSyms: string[]) => {
    setSymsState([...new Set(newSyms.filter(isValidCompassSymbol))]);
  };

  const setActiveWatchlistIds = (ids: number[]) => {
    const idsRemoved = activeWatchlistIds.filter((id) => !ids.includes(id));
    const idsAdded = ids.filter((id) => !activeWatchlistIds.includes(id));

    const watchlistSymsAdded = selectedWatchlistsSymbols(watchlists, idsAdded);
    const watchlistSymsRemoved = selectedWatchlistsSymbols(
      watchlists,
      idsRemoved,
    );

    setSyms(
      syms
        .concat(watchlistSymsAdded)
        .filter((s: string) => !watchlistSymsRemoved.includes(s)),
    );

    setActiveWatchlistIdsState(ids);
  };

  return {
    xAxis,
    yAxis,
    zAxis,
    mode,
    setXAxis,
    setYAxis,
    setZAxis,
    setMode,
    syms,
    setSyms,
    xAxisDataKey,
    yAxisDataKey,
    zAxisDataKey,
    editable: true, // will be used later
    setActiveWatchlistIds,
    setActiveWatchlistIdsState,
    activeWatchlistIds,
  };
};
