'use strict';
import { alpha, useTheme } from '@mui/material/styles';
import { decode } from '@msgpack/msgpack';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
  ReferenceLine,
  Label,
  Brush,
  ComposedChart,
} from 'recharts';
import { ErrorContent, Loader } from '../../shared';
import { DEFAULT_CHART_MARGINS, DEFAULT_Y_AXIS_STYLES } from 'config';
import {
  currentStatsState,
  iVolErrorState,
  showEconomicEventsState,
  vixDisplayTypeState,
  vixVisibleChartLinesState,
  vixSelectedDatesState,
  vixUsedLineColorsState,
  vixChartZoomConfigState,
  vixDateSelectorState,
  usEconomicEventsState,
} from 'states/iVol';
import {
  ET,
  getCurrentDate,
  getQueryDate,
  getFormattedDateFromUTC,
  roundToStartOfDay,
  prevBusinessDayOpenMarket,
} from 'util/shared';
import { ExpirationsDisplayType, CANDLE_IDX } from 'types/impliedVol';
import useImpliedVolatility from 'hooks/iVol/useImpliedVolatility';
import { getTradeDateDisplayText } from 'util/iVol';
import { isMobileState } from 'states/shared';
import dayjs from 'dayjs';
import useBrushZoom from 'hooks/useBrushZoom';
import { DTE_TICK_CONFIG } from 'util/shared/chart';
import { TIMESTAMP_TICK_CONFIG } from 'util/shared/chart';
import { getTicksBrushed } from 'util/shared/chart';
import { getZoomConfigRefArea } from 'util/shared/chart';
import { updateBrushZoomConfig } from 'util/shared/chart';
import ChartWatermarkContainer from 'components/shared/ChartWatermarkContainer';
import { ZoomOutButton } from 'components';
import { timezoneState } from 'states';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { EconomicCalendarData, ProductType } from 'types';
import useMacroCalendar from 'hooks/home/useMacroCalendar';
import useAuth from 'hooks/auth/useAuth';
import {
  useDefaultServiceGetVixQuote,
  useDefaultServiceGetCandles,
} from 'gen/stream-api/queries';
import { VixChartSettings } from './VixControls';

interface VixChartProps {
  includeControlsWidget?: bool;
}

const VixChart: ForwardRefRenderFunction<HTMLDivElement, VixChartProps> = (
  { includeControlsWidget },
  ref,
) => {
  const chartRef = useRef(null);
  const isMobile = useRecoilValue(isMobileState);
  const theme = useTheme();
  const { getMacroCalendar } = useMacroCalendar();
  const error = useRecoilValue(iVolErrorState);
  const [zoomConfig, setZoomConfig] = useRecoilState(vixChartZoomConfigState);
  const selectedDisplayType = useRecoilValue(vixDisplayTypeState);
  const currentTimezone = useRecoilValue(timezoneState);

  const [usEconomicEvents, setUsEconomicEvents] = useRecoilState(
    usEconomicEventsState,
  );

  const [vixSelectedDates, setVixSelectedDates] = useRecoilState(
    vixSelectedDatesState,
  );
  const [visibleChartLines, setVisibleChartLines] = useRecoilState(
    vixVisibleChartLinesState,
  );
  const setVixUsedLineColors = useSetRecoilState(vixUsedLineColorsState);
  const availableColors = Object.values(theme.palette.iVol.termStructure);
  const setSelectedDate = useSetRecoilState(vixDateSelectorState);

  const economicLabelFontSize = isMobile ? 10 : 13;

  useEffect(() => {
    if (vixSelectedDates === undefined) {
      const today = getQueryDate(true);
      const prevMarketDay = prevBusinessDayOpenMarket(today);
      const prevPrevMarketDay = prevBusinessDayOpenMarket(prevMarketDay);

      const initialSelectedDayjs = [today, prevMarketDay];

      const initialVixSelectedDates = initialSelectedDayjs.map((d, idx) => ({
        key: d.format('YYYY-MM-DD'),
        tradeDate: d,
        color: availableColors[idx] ?? theme.palette.primary.main,
      }));

      setVisibleChartLines(initialVixSelectedDates.map((el) => el.key));
      setVixUsedLineColors(initialVixSelectedDates.map((el) => el.color));
      setVixSelectedDates(initialVixSelectedDates);
      setSelectedDate(prevPrevMarketDay);
    }
  }, [
    availableColors,
    vixSelectedDates,
    setVixSelectedDates,
    setVixUsedLineColors,
    setVisibleChartLines,
    setSelectedDate,
    theme.palette.primary.main,
  ]);

  const showEconomicEvents = useRecoilValue(showEconomicEventsState);
  const { hasAccessToProduct } = useAuth();
  const hasIVolAccess = hasAccessToProduct(ProductType.IMPLIED_VOL);

  const { getStatisticsData } = useImpliedVolatility();

  const setCurrentStats = useSetRecoilState(currentStatsState);

  const xAxisField =
    selectedDisplayType === ExpirationsDisplayType.DaysToExpiration
      ? 'daysToExpiry'
      : 'expirationDate';

  const currentDate = getCurrentDate().format('YYYY-MM-DD');
  const {
    data: { items: vixQuotesSpot } = {},
    isLoading: vixQuotesSpotLoading,
  } = useDefaultServiceGetVixQuote({ dates: [currentDate] }, undefined, {
    refetchInterval: 5_000,
    enabled: !!vixSelectedDates?.some((s) => s.key === currentDate),
  });

  const nonCurrentDates = useMemo(
    () =>
      vixSelectedDates
        ? vixSelectedDates
            .filter((s) => s.key !== currentDate)
            .map((s) => s.key)
        : [],
    [vixSelectedDates, currentDate],
  );

  const {
    data: { items: vixQuotesHistory } = {},
    isLoading: vixQuotesHistoryLoading,
  } = useDefaultServiceGetVixQuote(
    { dates: [(nonCurrentDates ?? []).join(',')] },
    undefined,
    {
      enabled: !!nonCurrentDates?.length,
    },
  );

  const { data: latestVixPriceClose } = useDefaultServiceGetCandles(
    {
      sym: 'VIX',
      limit: 1,
      field: 'price',
      order: 'desc',
    },
    undefined,
    {
      refetchInterval: 10_000,
      select: (arrayBuffer) => decode(arrayBuffer)?.[0][CANDLE_IDX.CLOSE],
    },
  );

  const loading = vixQuotesSpotLoading || vixQuotesHistoryLoading;

  const [vixChartSeries, minQuoteDayjs, maxExpiryDayjs] = useMemo(() => {
    const vixChartSeries = [
      ...(vixQuotesSpot ?? []),
      ...(vixQuotesHistory ?? []),
    ]
      .filter((vixQuote) => /\/VX[A-Z][0-9]{2}/.test(vixQuote.sym)) // only use major quote dates
      .map((vixQuote) => {
        const quoteDayjs = dayjs(vixQuote.time).tz(ET);
        const quoteDate = quoteDayjs.format('YYYY-MM-DD');
        if (
          visibleChartLines.includes(quoteDate) &&
          (!!vixQuote.close || quoteDate === currentDate)
        ) {
          const expirationDayjs = dayjs(vixQuote.expiration_date).tz(ET);
          return {
            ...vixQuote,
            daysToExpiry: expirationDayjs.diff(quoteDayjs, 'days'),
            expirationDate: +expirationDayjs,
            expirationDayjs,
            quoteDate,
            quoteDayjs,
          };
        }
      })
      .filter((el) => el != null)
      .sort((a, b) => a[xAxisField] - b[xAxisField]);

    let minQuoteDayjs = undefined;
    let maxExpiryDayjs = undefined;

    if (vixChartSeries.length > 0) {
      minQuoteDayjs = dayjs(
        Math.min(...vixChartSeries.map((q) => q.quoteDayjs)),
      );
      maxExpiryDayjs = dayjs(
        Math.max(...vixChartSeries.map((q) => q.expirationDayjs)),
      );

      // splice in a dummy data-point to make the chart x-axis domain include the
      // x-axis origin of quote date or 0, depending on selectedDisplayType
      vixChartSeries.unshift({
        daysToExpiry: 0,
        expirationDate: +minQuoteDayjs,
      });

      // add a dummy data-point to the end of the chart x-axis domain so the last
      // point is not on the edge of the chart
      if (+maxExpiryDayjs > 0) {
        const max = dayjs(maxExpiryDayjs);
        vixChartSeries.push({
          daysToExpiry: Math.max(max.diff(minQuoteDayjs, 'days') + 3, 0),
          expirationDate: +max.add(3, 'days'),
        });
      }
    }

    return [vixChartSeries, minQuoteDayjs, maxExpiryDayjs];
  }, [
    vixQuotesSpot,
    vixQuotesHistory,
    visibleChartLines,
    xAxisField,
    currentDate,
  ]);

  // this effect is meant to initialize the chart data with previously added time-frames
  useEffect(() => {
    const fetchData = async () => {
      setCurrentStats(await getStatisticsData('VIX'));
    };
    fetchData();
  }, [getStatisticsData, setCurrentStats]);

  useEffect(() => {
    const getCalendarData = async () => {
      let calendarMap: Map<string, string[]> = new Map();

      if (vixChartSeries?.length > 0) {
        let beginDayjs = minQuoteDayjs.add(2, 'days');
        const calendarReqs = [];
        for (
          let watchdog = 0;
          watchdog < 5 && beginDayjs < maxExpiryDayjs;
          ++watchdog
        ) {
          const endDayjs = beginDayjs.add(60, 'days');
          calendarReqs.push(
            getMacroCalendar(
              beginDayjs.format('YYYY-MM-DD'),
              endDayjs.format('YYYY-MM-DD'),
            ),
          );
          beginDayjs = endDayjs;
        }

        const macroCalendarData: EconomicCalendarData[] = (
          await Promise.all(calendarReqs)
        ).reduce((ary, items) => ary.concat(items), []);

        const highPriUSData = macroCalendarData.filter(
          (calendarData: any) =>
            calendarData.impact === 'High' && calendarData.country === 'US',
        );

        calendarMap = highPriUSData.reduce((acc, obj) => {
          const dateStr = obj.date.split(' ')[0];
          const existingEvents = acc.get(dateStr) ?? [];
          acc.set(dateStr, existingEvents.concat([obj.event]));

          return acc;
        }, new Map<string, string[]>());
      }

      setUsEconomicEvents(calendarMap);
    };

    getCalendarData();
  }, [
    visibleChartLines,
    vixChartSeries,
    minQuoteDayjs,
    maxExpiryDayjs,
    setUsEconomicEvents,
    getMacroCalendar,
  ]);

  // Calculates which economic labels to display based on the width of a label
  // and its position in the chart.
  const economicDatesToDisplay = useMemo(() => {
    if (selectedDisplayType !== ExpirationsDisplayType.ExpirationDate) {
      return;
    }
    let lastDisplayedLabelXPixelOffset = undefined;
    const zoomBeginTimestamp =
      vixChartSeries[zoomConfig.leftIdx]?.expirationDate;
    const zoomEndTimestamp =
      vixChartSeries[
        zoomConfig.rightIdx >= 0 && zoomConfig.rightIdx < vixChartSeries.length
          ? zoomConfig.rightIdx
          : vixChartSeries.length - 1
      ]?.expirationDate;
    const entries = Array.from(usEconomicEvents?.entries() ?? []);
    entries.sort();
    return new Set(
      entries
        .map(([economicDate, _]) => {
          const economicDateTimestamp = +dayjs(economicDate);
          if (
            economicDateTimestamp > zoomEndTimestamp ||
            economicDateTimestamp < zoomBeginTimestamp
          ) {
            return null;
          }
          const displayedLabelXPixelOffset =
            (chartRef?.current?.container?.offsetWidth *
              (economicDateTimestamp - zoomBeginTimestamp)) /
            (zoomEndTimestamp - zoomBeginTimestamp);

          if (
            !lastDisplayedLabelXPixelOffset ||
            (displayedLabelXPixelOffset > 0 &&
              lastDisplayedLabelXPixelOffset + economicLabelFontSize <
                displayedLabelXPixelOffset)
          ) {
            lastDisplayedLabelXPixelOffset = displayedLabelXPixelOffset;
            return economicDate;
          }
          return null;
        })
        .filter((item) => item != null),
    );
  }, [
    usEconomicEvents,
    vixChartSeries,
    chartRef,
    zoomConfig,
    economicLabelFontSize,
    selectedDisplayType,
  ]);

  const combinedChartSeries = useMemo(() => {
    let combinedChartSeries = [...vixChartSeries];
    if (
      selectedDisplayType === ExpirationsDisplayType.ExpirationDate &&
      usEconomicEvents &&
      latestVixPriceClose
    ) {
      combinedChartSeries = combinedChartSeries.concat(
        Array.from(usEconomicEvents.keys()).map((date) => ({
          [xAxisField]: +dayjs(date),
          economicPseudoLineVal: latestVixPriceClose,
        })),
      );
      combinedChartSeries.sort((a, b) => a[xAxisField] - b[xAxisField]);
    }
    return combinedChartSeries;
  }, [
    vixChartSeries,
    xAxisField,
    usEconomicEvents,
    selectedDisplayType,
    latestVixPriceClose,
  ]);

  const { zoomChartConfig } = useBrushZoom<SymbolCandle>(
    zoomConfig,
    setZoomConfig,
    xAxisField,
    combinedChartSeries,
  );

  useEffect(() => {
    if (vixChartSeries) {
      updateBrushZoomConfig(zoomConfig, combinedChartSeries, setZoomConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setZoomConfig, combinedChartSeries]);

  const isWithinChartRange = (date: string): boolean => {
    if (vixChartSeries.length === 0) {
      return false;
    }
    const dateObj = dayjs(date);
    const minDate = minQuoteDayjs.add(2, 'days');
    const maxDate = maxExpiryDayjs;
    return (
      (dateObj.isSame(minDate, 'day') || dateObj.isAfter(minDate, 'day')) &&
      (dateObj.isSame(maxDate, 'day') || dateObj.isBefore(maxDate, 'day'))
    );
  };

  const ticks = useMemo(() => {
    return getTicksBrushed(
      combinedChartSeries.map((q) => q[xAxisField]),
      zoomConfig,
      xAxisField === 'expirationDate' ? TIMESTAMP_TICK_CONFIG : DTE_TICK_CONFIG,
    )?.map((tick: number) =>
      xAxisField === 'expirationDate' ? roundToStartOfDay(tick) : tick,
    );
  }, [combinedChartSeries, xAxisField, zoomConfig]);

  const chartTooltipShadow = `${theme.palette.background.paper} 1px 1px 4px, ${theme.palette.background.paper} 1px -1px 4px, ${theme.palette.background.paper} -1px 1px 4px, ${theme.palette.background.paper} -1px -1px 4px, ${theme.palette.background.paper} 2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px, ${theme.palette.background.paper} -2px 2px 4px, ${theme.palette.background.paper} 2px -2px 4px`;

  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active || !payload || !payload.length) {
      return null;
    }
    // find all nodes matching the x-axis cursor
    const selectedNodes = vixChartSeries.filter((q) => q[xAxisField] === label);

    const economicEvent =
      selectedDisplayType === ExpirationsDisplayType.ExpirationDate &&
      showEconomicEvents &&
      usEconomicEvents.get(getFormattedDateFromUTC(label))?.[0];

    if (!selectedNodes.length && !economicEvent) {
      return null;
    }

    selectedNodes.sort((a, b) => a.quoteDayjs - b.quoteDayjs);

    return (
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '12px',
          gap: '6px',
          color: theme.palette.text.primary,
          border: 'none',
          backgroundColor: alpha(theme.palette.background.paper, 0.85),
          boxShadow: theme.palette.shadows.paperBoxShadow,
          width: '275px',
        }}
      >
        <Grid container alignItems="center">
          {selectedNodes.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  {selectedDisplayType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  {selectedDisplayType === ExpirationsDisplayType.ExpirationDate
                    ? getFormattedDateFromUTC(label)
                    : label}
                </Typography>
              </Grid>
            </>
          )}
          {economicEvent && (
            <>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    width: '50%',
                  }}
                >
                  Key Event
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textAlign: 'end',
                  }}
                >
                  {economicEvent}
                </Typography>
              </Grid>
            </>
          )}

          {selectedNodes.length > 0 && (
            <>
              <Grid item xs={6} mt={4}>
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    textShadow: chartTooltipShadow,
                  }}
                >
                  Quote Date
                </Typography>
              </Grid>

              <Grid item xs={6} mt={4}>
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    textShadow: chartTooltipShadow,
                  }}
                >
                  Quote Price
                </Typography>
              </Grid>
            </>
          )}

          {selectedNodes.map((payloadObj: any, index: number) => {
            const keyBase = `${payloadObj.quoteDate}-${payloadObj.expirationDate}-${index}`;
            const color =
              vixSelectedDates?.find((s) => s.key === payloadObj.quoteDate)
                ?.color ?? '';
            return (
              <>
                <Grid item xs={6} key={index * 2}>
                  <Typography
                    key={`${keyBase}-a`}
                    sx={{
                      color: color,
                      fontSize: '13px',
                      textShadow: chartTooltipShadow,
                    }}
                  >
                    {payloadObj.quoteDate}
                  </Typography>
                </Grid>
                <Grid item xs={6} key={index * 2 + 1}>
                  <Typography
                    key={`${keyBase}-b`}
                    sx={{
                      color: color,
                      fontSize: '13px',
                      fontWeight: 'bold',
                      textShadow: chartTooltipShadow,
                    }}
                  >
                    {payloadObj.close}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Paper>
    );
  };

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  if (vixChartSeries.length === 0 && !loading && error != null) {
    return (
      <ErrorContent content="Failed to retrieve the data needed for Term Structure Chart. Please either refresh the page or contact us!" />
    );
  }
  const vixSettingsCompressed =
    (chartRef?.current?.container?.offsetHeight ?? 0) < 600;
  return (
    <ChartWatermarkContainer
      ref={ref ?? null}
      style={{
        flex: 1,
        position: 'relative',
      }}
      size={20}
      offsetX={50}
      offsetY={55}
      symStyles={{ opacity: vixSettingsCompressed ? 1 : undefined }}
      sym={
        includeControlsWidget ? (
          <Box>
            <ZoomOutButton
              key="vix-zoom"
              zoomConfig={zoomConfig}
              setZoomConfig={setZoomConfig}
              initialData={zoomConfig?.data ?? []}
              overrideDefault={{
                leftIdx: 0,
                rightIdx: (zoomConfig?.data?.length ?? 1) - 1,
              }}
            />
            <VixChartSettings compressed={vixSettingsCompressed} />
          </Box>
        ) : (
          'VIX'
        )
      }
    >
      {!hasIVolAccess && isMobile && (
        <Stack alignItems="center">
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: 14,
            }}
          >
            Access limited to <strong>{selectedSym}</strong>
          </Typography>
        </Stack>
      )}

      <ResponsiveContainer>
        <ComposedChart
          ref={chartRef}
          margin={{
            ...DEFAULT_CHART_MARGINS,
            bottom: isMobile ? 40 : 20,
            left: 15,
            right: 30,
          }}
          {...zoomChartConfig}
        >
          <CartesianGrid strokeDasharray="1 10" stroke={theme.palette.gray} />

          <XAxis
            allowDataOverflow
            label={{
              value: selectedDisplayType,
              fontSize: 12,
              offset: 3,
              position: 'insideBottom',
              fontWeight: 600,
              userSelect: 'none',
            }}
            dataKey={xAxisField}
            name={selectedDisplayType}
            domain={['dataMin', 'dataMax']}
            tick={{ fontSize: 10 }}
            type="number"
            tickFormatter={(val) =>
              selectedDisplayType === ExpirationsDisplayType.ExpirationDate
                ? getFormattedDateFromUTC(val)
                : val
            }
            ticks={ticks}
            tickCount={20}
          />
          <Brush
            dataKey={xAxisField}
            tickFormatter={(val) =>
              selectedDisplayType === ExpirationsDisplayType.ExpirationDate
                ? getFormattedDateFromUTC(val)
                : val
            }
            startIndex={zoomConfig.leftIdx}
            endIndex={zoomConfig.rightIdx}
            onChange={(brushIndices: any) =>
              setZoomConfig((prev) => ({
                ...prev,
                leftIdx: brushIndices.startIndex,
                rightIdx: brushIndices.endIndex,
              }))
            }
            height={25}
            travellerWidth={15}
            stroke={theme.palette.gray}
            fill={theme.palette.background.paper}
            alwaysShowText
          />
          <YAxis
            allowDataOverflow
            yAxisId="left"
            domain={[
              (dataMin: number) =>
                0.9 * Math.min(latestVixPriceClose ?? dataMin, dataMin),
              (dataMax: number) =>
                1.05 * Math.max(latestVixPriceClose ?? dataMax, dataMax),
            ]}
            tick={{ fontSize: 11 }}
            type="number"
            label={{
              ...DEFAULT_Y_AXIS_STYLES,
              offset: 0,
              value: 'Implied Volatility',
            }}
            tickFormatter={(value: number) => value.toFixed(3)}
          />

          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            wrapperStyle={{ fontSize: '12px' }}
            height={isMobile ? 60 : 40}
          />
          {visibleChartLines.map((quoteDate) => {
            const color =
              vixSelectedDates?.find((s) => s.key === quoteDate)?.color ?? '';
            return (
              <Line
                key={quoteDate}
                yAxisId="left"
                type="linear"
                dataKey={(q) =>
                  q.quoteDate === quoteDate ? q.close : undefined
                }
                name={getTradeDateDisplayText(
                  dayjs(quoteDate),
                  currentTimezone,
                )}
                stroke={color}
                dot={{ color, strokeWidth: 2 }}
                strokeWidth={3}
                connectNulls
              />
            );
          })}

          <Line
            yAxisId="left"
            type="linear"
            name="economic-pseudo-line"
            dataKey={(q) => q.economicPseudoLineVal && latestVixPriceClose}
            stroke="#0000"
            fill="#0000"
            dot={{ color: '#0000', strokeWidth: 0, r: 0 }}
            strokeWidth={0}
            connectNulls
          />

          {latestVixPriceClose && (
            <ReferenceLine
              y={latestVixPriceClose}
              isFront
              yAxisId="left"
              strokeDasharray="6 5"
            >
              <Label
                position="left"
                style={{
                  textAnchor: 'end',
                  fontSize: isMobile ? 10 : 13,
                  fontWeight: 500,
                  userSelect: 'none',
                  background: theme.palette.hiro.lenses.price.total,
                }}
              >
                {latestVixPriceClose.toFixed(3)}
              </Label>
            </ReferenceLine>
          )}

          {selectedDisplayType === ExpirationsDisplayType.ExpirationDate &&
            showEconomicEvents &&
            Array.from(usEconomicEvents?.entries() ?? []).map(
              ([date, events]: [string, string[]]) => {
                return isWithinChartRange(date) &&
                  economicDatesToDisplay?.has(date) ? (
                  <ReferenceLine
                    key={date}
                    x={dayjs(date).valueOf()}
                    isFront
                    stroke="transparent"
                    ifOverflow="visible"
                    yAxisId="left"
                  >
                    <Label
                      position="insideBottom"
                      angle={-90}
                      style={{
                        textAnchor: 'start',
                        fontSize: economicLabelFontSize,
                        fontWeight: 500,
                        userSelect: 'none',
                      }}
                    >
                      {events[0]}
                    </Label>
                  </ReferenceLine>
                ) : null;
              },
            )}
          {getZoomConfigRefArea(zoomConfig, 'left')}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWatermarkContainer>
  );
};

export default forwardRef(VixChart);
