import { Typography } from '@mui/material';
import {
  CompassChartData,
  CompassDesc,
  CompassSeverity,
  CompassSymbolData,
  StrategyCompassMode,
  StrategyCompassXYZAxis,
  StrategyCompassXYZAxisNotPercents,
  StrategyCompassXYZAxisReadableMap,
} from '../types/compass';
import { COMBINED_SIGNALS, SCANNER_TOOLTIP_MAP } from '../config';
import { CompassParams } from '../hooks/scanners/useCompassParams';
import { EquityFieldKey } from 'types/equityhub';

const VOWELS = new Set(['a', 'e', 'i', 'o', 'u']);
export const aOrAn = (word: string) => {
  if (!word || word.length === 0) {
    return word || '';
  }
  return `${VOWELS.has(word[0].toLowerCase()) ? 'an' : 'a'} ${word}`;
};

export const toPercentString = (num: number) => (num * 100.0).toFixed(2);

export const getTradeInfo = (
  data: CompassChartData,
  xAxis: StrategyCompassXYZAxis,
  yAxis: StrategyCompassXYZAxis,
  zAxis?: StrategyCompassXYZAxis,
) => {
  const xSev = calculateSeverity(data.x, xAxis);
  const ySev = calculateSeverity(data.y, yAxis);
  const zSev =
    zAxis && data.z != null ? calculateSeverity(data.z!, yAxis) : undefined;

  return {
    tooltipXDesc: (COMPASS_DESCRIPTIONS[xSev] as CompassDesc)[xAxis],
    tooltipYDesc: (COMPASS_DESCRIPTIONS[ySev] as CompassDesc)[yAxis],
    tooltipZDesc:
      zSev != null
        ? (COMPASS_DESCRIPTIONS[zSev] as CompassDesc)[zAxis!]
        : undefined,
    trade: getTrade(xSev, ySev),
  };
};

export const COMPASS_DESCRIPTIONS = {
  [CompassSeverity.VERY_SLIGHT]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'very high chance of a bullish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'extremely cheap',
    [StrategyCompassXYZAxis.IvPct]: 'extremely cheap',
    [StrategyCompassXYZAxis.Rsi]: 'extremely oversold',
  },
  [CompassSeverity.SLIGHT]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'high chance of a bullish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'very cheap',
    [StrategyCompassXYZAxis.IvPct]: 'very cheap',
    [StrategyCompassXYZAxis.Rsi]: 'very oversold',
  },
  [CompassSeverity.MILD]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'above average chance of a bullish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'cheap',
    [StrategyCompassXYZAxis.IvPct]: 'cheap',
    [StrategyCompassXYZAxis.Rsi]: 'oversold',
  },
  [CompassSeverity.NEUTRAL_LOW]: {
    [StrategyCompassXYZAxis.Bollinger]: 'average bullish price reversal risk',
    [StrategyCompassXYZAxis.IvRank]: 'slightly cheap',
    [StrategyCompassXYZAxis.IvPct]: 'slightly cheap',
    [StrategyCompassXYZAxis.Rsi]: 'neither oversold or overbought',
  },
  [CompassSeverity.NEUTRAL_HIGH]: {
    [StrategyCompassXYZAxis.Bollinger]: 'average bearish price reversal risk',
    [StrategyCompassXYZAxis.IvRank]: 'slightly expensive',
    [StrategyCompassXYZAxis.IvPct]: 'slightly expensive',
    [StrategyCompassXYZAxis.Rsi]: 'neither oversold or overbought',
  },
  [CompassSeverity.MODERATE]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'above average chance of a bearish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'expensive',
    [StrategyCompassXYZAxis.IvPct]: 'expensive',
    [StrategyCompassXYZAxis.Rsi]: 'overbought',
  },
  [CompassSeverity.HIGH]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'high chance of a bearish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'very expensive',
    [StrategyCompassXYZAxis.IvPct]: 'very expensive',
    [StrategyCompassXYZAxis.Rsi]: 'very overbought',
  },
  [CompassSeverity.VERY_HIGH]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'very high chance of a bearish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'extremely expensive',
    [StrategyCompassXYZAxis.IvPct]: 'extremely expensive',
    [StrategyCompassXYZAxis.Rsi]: 'extremely overbought',
  },
};

const getTrade = (xSev: CompassSeverity, ySev: CompassSeverity) => {
  if (ySev === CompassSeverity.NEUTRAL_LOW) {
    if (xSev <= CompassSeverity.NEUTRAL_LOW) {
      return 'buying call spreads';
    } else {
      return 'buying put spreads';
    }
  } else if (ySev === CompassSeverity.NEUTRAL_HIGH) {
    if (xSev <= CompassSeverity.NEUTRAL_LOW) {
      return 'selling put spreads';
    } else {
      return 'selling call spreads';
    }
  } else if (ySev < CompassSeverity.NEUTRAL_LOW) {
    if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'buying call spreads';
    } else {
      return 'buying put spreads';
    }
  } else if (ySev > CompassSeverity.NEUTRAL_HIGH) {
    if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'selling put spreads';
    } else {
      return 'selling call spreads';
    }
  }

  return '';
};

export const generateTooltipTextComponent = (
  value: number,
  axis: StrategyCompassXYZAxis | undefined,
  severityDescription: string | undefined,
  data: CompassSymbolData,
) => {
  if (axis == null || severityDescription == null) {
    return null;
  }

  const displayVal = displayValue(value, axis);

  switch (axis) {
    case StrategyCompassXYZAxis.IvRank:
      return (
        <Typography>
          {data.sym} has a 1 month IV in the <b>{displayVal}</b> of its 52-wk
          range, indicating {severityDescription} IV relative to its history.
        </Typography>
      );
    case StrategyCompassXYZAxis.IvPct:
      return (
        <Typography>
          {data.sym} has a 1 month IV which is higher than it has been for{' '}
          <b>{displayVal}</b> of the past 52 weeks, indicating{' '}
          {severityDescription} IV relative to its history.
        </Typography>
      );
    case StrategyCompassXYZAxis.Bollinger:
      return (
        <Typography>
          {data.sym}'s current price is in the <b>{displayVal}</b> of it's
          Bollinger Band range, indicating {aOrAn(severityDescription)}.
        </Typography>
      );
    case StrategyCompassXYZAxis.ProximityToCallWall:
      return (
        <Typography>
          {data.sym}, at its current price of {data.price.toFixed(2)}, is{' '}
          <b>{displayVal}</b> away from its Call Wall.
        </Typography>
      );
    case StrategyCompassXYZAxis.ProximityToPutWall:
      return (
        <Typography>
          {data.sym}, at its current price of {data.price.toFixed(2)}, is{' '}
          <b>{displayVal}</b> away from its Put Wall.
        </Typography>
      );
    case StrategyCompassXYZAxis.NextExpiryCallVolumePercent:
      return (
        <Typography>
          {data.sym} has <b>{displayVal}</b> of its total call volume expiring
          after the next expiration.
        </Typography>
      );
    case StrategyCompassXYZAxis.NextExpiryDeltaPercent:
      return (
        <Typography>
          {data.sym} has <b>{displayVal}</b> of its total delta expiring after
          the next expiration.
        </Typography>
      );
    case StrategyCompassXYZAxis.NextExpiryGammaPercent:
      return (
        <Typography>
          {data.sym} has <b>{displayVal}</b> of its total gamma expiring after
          the next expiration.
        </Typography>
      );
    case StrategyCompassXYZAxis.Rsi:
      return (
        <Typography>
          {data.sym} has an RSI of <b>{displayVal}</b>, indicating that it is{' '}
          {severityDescription}.
        </Typography>
      );
    default:
      return (
        <Typography>
          {data.sym} has a {StrategyCompassXYZAxisReadableMap.get(axis)!} of{' '}
          <b>{displayVal}</b>;
        </Typography>
      );
  }
};

export const generateTooltipText = (
  data: CompassChartData,
  symData: CompassSymbolData,
  compassParams: CompassParams,
) => {
  const { xAxis, yAxis, zAxis, mode } = compassParams;
  const { tooltipXDesc, tooltipYDesc, tooltipZDesc, trade } = getTradeInfo(
    data,
    xAxis,
    yAxis,
    zAxis,
  );
  return (
    <>
      {generateTooltipTextComponent(
        data.unroundedX,
        xAxis,
        tooltipXDesc,
        symData,
      )}
      {generateTooltipTextComponent(
        data.unroundedY,
        yAxis,
        tooltipYDesc,
        symData,
      )}
      {data.unroundedZ != null &&
        generateTooltipTextComponent(
          data.unroundedZ,
          zAxis,
          tooltipZDesc,
          symData,
        )}
      {mode === StrategyCompassMode.Compass && (
        <>
          <Typography>
            In this environment, traders might consider{' '}
            <Typography fontWeight="bold" sx={{ textDecoration: 'underline' }}>
              {trade}
            </Typography>
            .
          </Typography>
          <Typography fontSize="9px" marginTop="5px">
            All trading involves risk. The information provided here is not
            investment advice. Your trades are solely the result of your own
            actions, and SpotGamma assumes no liability for them.
          </Typography>
        </>
      )}
    </>
  );
};

export const calculateSeverity = (
  val: number,
  axis: StrategyCompassXYZAxis,
) => {
  const thresholds =
    axis === StrategyCompassXYZAxis.Rsi
      ? [0.1, 0.2, 0.31, 0.5, 0.7, 0.8, 0.9]
      : [0.075, 0.175, 0.25, 0.5, 0.75, 0.85, 0.925];
  // values here for NEUTRAL_LOW and NEUTRAL_HIGH should match SPREAD_THRESHOLD in StrategyCompass
  // to ensure that values in the spread boxes print out the correct recommendation
  if (val < thresholds[0]) {
    return CompassSeverity.VERY_SLIGHT;
  } else if (val < thresholds[1]) {
    return CompassSeverity.SLIGHT;
  } else if (val < thresholds[2]) {
    return CompassSeverity.MILD;
  } else if (val >= thresholds[2] && val < thresholds[3]) {
    return CompassSeverity.NEUTRAL_LOW;
  } else if (val >= thresholds[3] && val <= thresholds[4]) {
    return CompassSeverity.NEUTRAL_HIGH;
  } else if (val > thresholds[4] && val <= thresholds[5]) {
    return CompassSeverity.MODERATE;
  } else if (val > thresholds[5] && val < thresholds[6]) {
    return CompassSeverity.HIGH;
  } else {
    // 92.5 - 100
    return CompassSeverity.VERY_HIGH;
  }
};

export const isValidCompassSymbol = (sym: string) => {
  return (sym?.length ?? 0) > 0 && !COMBINED_SIGNALS.has(sym);
};

export const getModeLabel = (mode: StrategyCompassMode) => {
  switch (mode) {
    case StrategyCompassMode.Compass:
      return 'Guided View';
    case StrategyCompassMode.Freeform:
      return 'Explorer View';
  }
};

export const calculateDistanceToWall = (price: number, ws: number) =>
  Math.abs(ws - price) / price;

export const zeroOneBound = (num: number | undefined | null) => {
  if (num == null) {
    return undefined;
  }
  // these values could technically be negative or >1. for now, simplest thing is to just 0,1 bound them
  return Math.max(Math.min(num, 1), 0);
};

export const displayValue = (val: number, axis: StrategyCompassXYZAxis) =>
  StrategyCompassXYZAxisNotPercents.has(axis)
    ? `${toPercentString(val)}`
    : `${toPercentString(val)}%`;

export const generateAxisLabelTooltip = (
  severity: 'high' | 'low',
  axis: StrategyCompassXYZAxis,
) => {
  const rrText = `This metric measures the difference in implied volatility between calls and puts relative to the stock’s recent history. A ${severity} risk reversal percentile indicates ${
    severity === 'high' ? 'downside' : 'upside'
  } potential.`;

  const rsiText = `RSI (Relative Strength Index) measures the value of a stock based on its recent price history, based on the speed and change of price movements. A ${severity} RSI signifies potential ${
    severity === 'high' ? 'selling' : 'buying'
  } conditions.`;

  const bbandText = `Bollinger bands measure price in relation to its recent history. A ${severity} Bollinger Band % indicates a risk of price reverting lower, indicating a ${
    severity === 'high' ? 'bearish' : 'bullish'
  } sentiment.`;

  const ivRankText = `IV Rank measures a stock’s current Implied Volatility rank relative to the prior year. A ${severity} IV Rank suggests that volatility is relatively ${severity} for the stock, and options are relatively ${
    severity === 'high' ? 'expensive' : 'cheap'
  }.`;

  const ivPercentileText = `IV Percentile indicates where current implied volatility resides compared to the prior year. A ${severity} IV percentile indicates that implied volatility is ${severity} compared to the prior year, and that options may be ${
    severity === 'high' ? 'expensive' : 'cheap'
  } for the stock.`;

  const getSkewPercentileText = (pOrC: 'put' | 'call') =>
    `This measures how high implied volatility is for ${pOrC}s compared to the stock’s recent trading history, with a larger percentile reflecting higher than normal call IV.`;

  const getProximityToWallText = (pOrC: 'put' | 'call') =>
    `This measures the distance between current price and the stock’s ${pOrC} wall. A lower percentage indicates the stock is closer to its ${pOrC} wall, while a larger percentage indicates it is further away. Once the ${pOrC} wall is broken, the stock will show up on the border of the grid.`;

  switch (axis) {
    case StrategyCompassXYZAxis.RRPercentile:
      return rrText;
    case StrategyCompassXYZAxis.Rsi:
      return rsiText;
    case StrategyCompassXYZAxis.Bollinger:
      return bbandText;
    case StrategyCompassXYZAxis.IvRank:
      return ivRankText;
    case StrategyCompassXYZAxis.IvPct:
      return ivPercentileText;
    case StrategyCompassXYZAxis.CallSkewPercentile:
      return getSkewPercentileText('call');
    case StrategyCompassXYZAxis.PutSkewPercentile:
      return getSkewPercentileText('put');
    case StrategyCompassXYZAxis.ProximityToCallWall:
      return getProximityToWallText('call');
    case StrategyCompassXYZAxis.ProximityToPutWall:
      return getProximityToWallText('put');
    case StrategyCompassXYZAxis.NextExpiryDeltaPercent:
      return SCANNER_TOOLTIP_MAP.get(EquityFieldKey.nextExpGamma);
    case StrategyCompassXYZAxis.NextExpiryGammaPercent:
      return SCANNER_TOOLTIP_MAP.get(EquityFieldKey.nextExpGamma);
    case StrategyCompassXYZAxis.NextExpiryCallVolumePercent:
      return SCANNER_TOOLTIP_MAP.get(EquityFieldKey.nextExpCallVol);
    case StrategyCompassXYZAxis.NextExpiryPutVolumePercent:
      return SCANNER_TOOLTIP_MAP.get(EquityFieldKey.nextExpPutVol);
    case StrategyCompassXYZAxis.GarchRank:
      return SCANNER_TOOLTIP_MAP.get(EquityFieldKey.garchRank);
    case StrategyCompassXYZAxis.OneMonthIv:
      return SCANNER_TOOLTIP_MAP.get(EquityFieldKey.iv30);
    case StrategyCompassXYZAxis.OneMonthRv:
      return SCANNER_TOOLTIP_MAP.get(EquityFieldKey.rv30);
    default:
      return '';
  }
};
