import {
  Box,
  Stack,
  Tab,
  Tabs as MuiTabs,
  Typography,
  useTheme,
  IconButton,
  Popper,
  Fade,
  Paper,
} from '@mui/material';
import StockIcon from 'components/StockIcon';
import { ReactNode, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { isMobileState } from 'states';
import { TabItem } from 'types/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface TabbedContentProps {
  tabs: TabItem[];
  defaultTab?: string;
  staticActions?: ReactNode[];
  onChange?: (value: string) => void;
  currentSym?: string;
}

const TabbedContent = ({
  tabs,
  defaultTab,
  staticActions = [],
  onChange,
  currentSym,
}: TabbedContentProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const [currentTab, setCurrentTab] = useState<string>(
    defaultTab || (tabs.length > 0 ? tabs[0].value : ''),
  );
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(
    null,
  );
  const actionsMenuOpen = Boolean(mobileMenuAnchor);
  const mobileMenuButtonRef = useRef<HTMLButtonElement>(null);

  const handleChange = (_evt: React.SyntheticEvent, value: string) => {
    setCurrentTab(value);
    if (onChange) {
      onChange(value);
    }
  };

  const handleOpenActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    // Toggle the menu - close if already open, open if closed
    if (actionsMenuOpen) {
      setMobileMenuAnchor(null);
    } else {
      setMobileMenuAnchor(event.currentTarget);
    }
  };

  const currentTabItem = tabs.find((tab) => tab.value === currentTab);
  const currentTabActions = currentTabItem?.actions || [];
  const allActions = [...currentTabActions, ...staticActions].filter(Boolean);

  return (
    <Box
      sx={{
        transition: '0.5s',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: isMobile ? '550px' : '100%',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        p: {
          xs: '4px',
          md: '8px',
          lg: '16px',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: 0, // Allow this box to shrink below content size
            flex: '1 1 auto', // Allow this box to grow and shrink
            overflow: 'hidden', // Prevent overflow
          }}
        >
          {currentSym && (
            <Stack
              sx={{
                gap: 3,
                flexDirection: 'row',
                alignItems: 'center',
                mr: 2,
                flexShrink: 0, // Don't shrink the symbol
              }}
            >
              <StockIcon symbol={currentSym} />
              <Typography sx={{ fontSize: { xs: 14, md: 18 } }}>
                {currentSym}
              </Typography>
            </Stack>
          )}
          <MuiTabs
            value={currentTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              minWidth: 0, // Allow tabs to shrink
              '& .MuiTabs-flexContainer': {
                gap: 1,
              },
              '& .MuiTabs-root': {
                overflow: 'hidden',
              },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: isMobile ? '12px' : '14px',
                minWidth: 'auto',
                px: 2,
              },
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </MuiTabs>
        </Box>

        {isMobile ? (
          <>
            <IconButton
              ref={mobileMenuButtonRef}
              color="primary"
              onClick={handleOpenActionsMenu}
              sx={{ ml: 1, flexShrink: 0 }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popper
              open={actionsMenuOpen}
              anchorEl={mobileMenuAnchor}
              placement="bottom-end"
              transition
              style={{ zIndex: 1300 }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={250}>
                  <Paper
                    elevation={3}
                    sx={{
                      maxHeight: 300,
                      maxWidth: 150,
                      p: 1,
                      mt: 0.5,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {allActions.map((action, index) => (
                      <Box
                        key={`mobile-action-${index}`}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          p: 1,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {action}
                      </Box>
                    ))}
                  </Paper>
                </Fade>
              )}
            </Popper>
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              ml: 2,
              alignItems: 'center',
              flexShrink: 0, // Don't shrink the action buttons
            }}
          >
            {currentTabActions.map((action, index) => (
              <Box key={`tab-action-${index}`}>{action}</Box>
            ))}
            {staticActions.map((action, index) => (
              <Box key={`static-action-${index}`}>{action}</Box>
            ))}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          overflowX: 'auto',
          mt: 4,
        }}
      >
        {currentTabItem && (
          <Box
            sx={{
              transition: '0.5s',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '100%',
              width: '100%',
            }}
          >
            {currentTabItem.content}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TabbedContent;
