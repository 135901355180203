import { EquityGraph } from '../../components';
import { EquityhubStockScanner } from '../../components/equityhub/EquityhubTable/EquityhubStockScanner';
import {
  Equity,
  ProductType,
  SearchHandlerFunction,
  Scanner,
  EquityFieldKey,
} from '../../types';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useCleanup from '../../hooks/useCleanup';
import {
  activeSearchState,
  ehActiveScannerState,
  ehSelectedScannersState,
  ehSelectedWatchlistIdsState,
  ehWatchlistsAndScannersExpandedState,
  equitiesFetchLoadingState,
  equityExpandedViewFieldsState,
  equityMainViewFieldsState,
  equitySymbolsState,
  freeSymbolState,
  searchHandlerState,
  searchSuggestionsData,
  selectedEquitySymbolState,
  unpricedEquitiesState,
  watchlistsState,
} from '../../states';
import { useSetSym } from '../../hooks';
import useAuth from '../../hooks/auth/useAuth';
import { ResizableDashboardLayout } from 'layouts/ResizableDashboardLayout';
import usePrices from 'hooks/equityhub/usePrices';
import EquityDetails from 'components/equityhub/EquityDetails/EquityDetails';
import WatchlistsAndScanners from 'components/equityhub/Watchlists/WatchlistsAndScanners';
import { SelectChangeEvent } from '@mui/material';
import { getEquitiesForScanner } from 'util/equityhub';
import { EH_ALL_EQUITY_DETAILS_FIELDS } from 'config/equityhub';
import useToast from 'hooks/useToast';

const MAIN_SCANNERS = [Scanner.VOL_RISK_PREMIUM, Scanner.SQUEEZE];

export const Equityhub = () => {
  const equitiesLoading = useRecoilValue(equitiesFetchLoadingState);
  const unpricedEquities = useRecoilValue(unpricedEquitiesState);
  const [selectedSym, setSelectedSym] = useRecoilState(
    selectedEquitySymbolState,
  );
  const { openToast } = useToast();
  const watchlists = useRecoilValue(watchlistsState);
  const [watchlistsAndScannersExpanded, setWatchlistsAndScannersExpanded] =
    useRecoilState(ehWatchlistsAndScannersExpandedState);
  const setActiveSearch = useSetRecoilState(activeSearchState);
  const { hasAccessToProduct } = useAuth();
  const hasEquityhubAccess = hasAccessToProduct(ProductType.EQUITYHUB);
  const setActiveScanner = useSetRecoilState(ehActiveScannerState);
  const equityInstruments = useRecoilValue(equitySymbolsState);
  const liveEhSymbol = useRecoilValue(freeSymbolState);
  const [isPricesLoading, setIsPricesLoading] = useState<boolean>(false);
  const { getPrices } = usePrices();
  const { setSym, getSymRaw, sym } = useSetSym();
  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);
  const [selectedWatchlistIds, setSelectedWatchlistIds] = useRecoilState(
    ehSelectedWatchlistIdsState,
  );
  const [selectedScanners, setSelectedScanners] = useRecoilState(
    ehSelectedScannersState,
  );

  const [pricedEquities, setPricedEquities] = useState<Equity[]>([]);

  const [equityMainViewFields, setEquityMainViewFields] = useRecoilState(
    equityMainViewFieldsState,
  );
  const [equityExpandedViewFields, setEquityExpandedViewFields] =
    useRecoilState(equityExpandedViewFieldsState);

  const equityOtherFields = useMemo(() => {
    return EH_ALL_EQUITY_DETAILS_FIELDS.filter(
      (field) =>
        !equityMainViewFields.includes(field) &&
        !equityExpandedViewFields.includes(field),
    );
  }, [equityMainViewFields, equityExpandedViewFields]);

  useCleanup();

  useEffect(() => {
    // search suggestions data handler
    const suggestionsHandler: SearchHandlerFunction = (value: string): void => {
      const sym = value.toUpperCase();
      const ehSym = unpricedEquities.has(sym) ? sym : undefined;
      setSelectedSym(ehSym);
      setSym(sym, ProductType.EQUITYHUB);
    };

    setSuggestionsHandler(() => suggestionsHandler);
  }, [unpricedEquities, setSelectedSym, setSym, setSuggestionsHandler]);

  useEffect(() => {
    // search suggestions data
    setSuggestionsData(equityInstruments);
  }, [equityInstruments, setSuggestionsData]);

  useEffect(() => {
    if (!hasEquityhubAccess) {
      const newSym = setSym(liveEhSymbol, ProductType.EQUITYHUB);
      setActiveScanner(undefined);
      setSelectedSym(newSym);
      setActiveSearch(newSym);
      return;
    }

    let newSym = getSymRaw();
    // Check if sym is not null and is available, otherwise use default
    if (
      !equitiesLoading &&
      unpricedEquities.size > 0 &&
      !unpricedEquities.has(sym)
    ) {
      newSym = null; // setSym will automatically set to default
    }

    const finalSym = setSym(newSym, ProductType.EQUITYHUB);
    setSelectedSym(finalSym);
    setActiveSearch(finalSym);
  }, [
    equitiesLoading,
    unpricedEquities,
    sym,
    setSelectedSym,
    setActiveSearch,
    hasEquityhubAccess,
    liveEhSymbol,
    setSym,
  ]);

  useEffect(() => {
    if (unpricedEquities.size === 0) {
      return;
    }

    const getUpdatedPricedEquities = async () => {
      setIsPricesLoading(true);
      const payload = await getPrices([]);
      setPricedEquities(
        Array.from(unpricedEquities.values()).map((e: Equity) => ({
          ...e,
          [EquityFieldKey.price]: payload.get(e.sym),
          [EquityFieldKey.dailyChange]:
            payload.get(e.sym) && e.upx
              ? (payload.get(e.sym)! - e.upx) / e.upx
              : undefined,
        })),
      );
      setIsPricesLoading(false);
    };

    getUpdatedPricedEquities();
  }, [unpricedEquities]);

  const pricedEquity = useMemo(
    () => pricedEquities.find((eq) => eq.sym === selectedSym),
    [pricedEquities, selectedSym],
  );

  const isLoading = useMemo(() => {
    return equitiesLoading || isPricesLoading;
  }, [equitiesLoading, isPricesLoading]);

  const onWatchlistSelectChange = (
    event: SelectChangeEvent<string | string[]>,
  ) => {
    setSelectedWatchlistIds(event.target.value as string[]);
  };

  const onScannerSelectChange = (
    event: SelectChangeEvent<string | string[]>,
  ) => {
    setSelectedScanners(event.target.value as Scanner[]);
  };

  const watchlistsAndScannerEquities: Equity[] = useMemo(() => {
    let result: Equity[] = [];

    if (selectedWatchlistIds.length > 0) {
      const selectedWatchlists =
        watchlists?.filter(
          (w) => w.id && selectedWatchlistIds.includes(w.id.toString()),
        ) ?? [];
      const watchlistSymbols = new Set(
        selectedWatchlists.flatMap((w) => w.symbols),
      );
      result = pricedEquities.filter((eq) => watchlistSymbols.has(eq.sym));
    }

    if (selectedScanners.length > 0) {
      const scannerEquities = selectedScanners.flatMap((scanner) =>
        getEquitiesForScanner(scanner, pricedEquities),
      );
      const scannerSymbols = new Set(scannerEquities.map((eq) => eq.sym));
      result = pricedEquities.filter((eq) => scannerSymbols.has(eq.sym));
    }

    // Use object to deduplicate and preserve first occurrence of each symbol
    return Object.values(
      result.reduce((acc, equity) => {
        if (!acc[equity.sym]) {
          acc[equity.sym] = equity;
        }
        return acc;
      }, {} as Record<string, Equity>),
    );
  }, [pricedEquities, selectedWatchlistIds, selectedScanners]);

  const onEquityClick = (equity: Equity) => {
    setSym(equity.sym, ProductType.EQUITYHUB);
    setSelectedSym(equity.sym);
  };

  const onWatchlistsAndScannersToggleExpanded = () => {
    setWatchlistsAndScannersExpanded((prev) => !prev);
  };

  const onEditFields = (
    mainViewFields: EquityFieldKey[],
    expandedViewFields: EquityFieldKey[],
  ) => {
    setEquityMainViewFields(mainViewFields);
    setEquityExpandedViewFields(expandedViewFields);
    openToast({
      message: 'Fields updated',
      type: 'success',
    });
  };

  return (
    <ResizableDashboardLayout
      dashboardId={ProductType.EQUITYHUB}
      mainContent={<EquityGraph />}
      sidebarTopContent={
        <EquityDetails
          equity={pricedEquity}
          isLoading={isLoading}
          isExpanded={!watchlistsAndScannersExpanded}
          onToggleExpanded={onWatchlistsAndScannersToggleExpanded}
          equityMainViewFields={equityMainViewFields}
          equityExpandedViewFields={equityExpandedViewFields}
          equityOtherFields={equityOtherFields}
          onEditFields={onEditFields}
        />
      }
      sidebarBottomContent={
        <WatchlistsAndScanners
          watchlists={watchlists ?? []}
          scanners={MAIN_SCANNERS}
          selectedWatchlists={selectedWatchlistIds.filter(Boolean)}
          selectedScanners={selectedScanners}
          onWatchlistSelectChange={onWatchlistSelectChange}
          onScannerSelectChange={onScannerSelectChange}
          equities={watchlistsAndScannerEquities}
          onEquityClick={onEquityClick}
          isExpanded={watchlistsAndScannersExpanded}
          onToggleExpanded={onWatchlistsAndScannersToggleExpanded}
          selectedEquities={selectedSym ? [selectedSym] : []}
        />
      }
      bottomContent={<EquityhubStockScanner />}
      bottomContentHeaderName="Equities"
    />
  );
};
