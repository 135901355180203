import {
  LabelList,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import {
  StrategyCompassXYZAxis,
  StrategyCompassXYZAxisReadableMap,
} from '../../../types';
import { useEffect, useRef, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  equitiesFetchLoadingState,
  isMobileState,
  negativeTrendColorState,
  positiveTrendColorState,
} from '../../../states';
import { difference } from 'lodash';
import { Box } from '@mui/material';
import { CompassZoomDomain, StrategyCompassMode } from '../../../types/compass';
import { generateAxisLabelTooltip, isValidCompassSymbol } from '../../../util';
import { compassHoveredSymbolState } from '../../../states/compass';
import { StrategyCompassControls } from './StrategyCompassControls';
import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import { StrategyCompassTooltip } from './StrategyCompassTooltip';
import { Loader } from '../../shared';
import { CompassData } from '../../../hooks/scanners/useCompassData';
import { SGTooltip } from 'components/core';
import {
  compassZoomDomainState,
  compassIsZoomedState,
  compassZoomStartPointState,
  compassZoomEndPointState,
  compassIsSelectingState,
} from '../../../states/compass';
import { COMPASS_DEFAULT_ZOOM_DOMAIN_WITH_BUFFER } from 'config/compass';

const labelColor = '#a4a4a4';
const EDGE_ALLOWED_SPACING = 30;

const X_MIN = 0;
const X_MAX = 1;
const SEGMENT_PADDING = X_MAX * 0.02;
const Y_MIN = 0;
const Y_MAX = 1;
const X_MID = (X_MIN + X_MAX) / 2.0;
const Y_MID = (Y_MIN + Y_MAX) / 2.0;

type StrategyCompassProps = {
  compassParams: CompassParams;
  compassData: CompassData;
};

export const StrategyCompass = ({
  compassParams,
  compassData,
}: StrategyCompassProps) => {
  const { mode, syms, xAxis, yAxis, editable, zAxis } = compassParams;
  const { fetchData, loading, chartData, data, visibleChartData } = compassData;

  const theme = useTheme();
  const axisTextColor = theme.palette.text.secondary;
  const positiveTrend = useRecoilValue(positiveTrendColorState);
  const negTrend = useRecoilValue(negativeTrendColorState);
  const equitiesLoading = useRecoilValue(equitiesFetchLoadingState);
  const hoveredSymbol = useRecoilValue(compassHoveredSymbolState);
  const isMobile = useRecoilValue(isMobileState);

  const fontSize = isMobile ? 11 : 13;
  const [chartSize, setChartSize] = useState<{ width: number; height: number }>(
    { width: 0, height: 0 },
  );

  // Zoom state using Recoil
  const [zoomDomain, setZoomDomain] = useRecoilState(compassZoomDomainState);
  const [isZoomed, setIsZoomed] = useRecoilState(compassIsZoomedState);
  const chartRef = useRef<any>(null);

  // Box selection zoom state using Recoil
  const [zoomStartPoint, setZoomStartPoint] = useRecoilState(
    compassZoomStartPointState,
  );
  const [zoomEndPoint, setZoomEndPoint] = useRecoilState(
    compassZoomEndPointState,
  );
  const [isSelecting, setIsSelecting] = useRecoilState(compassIsSelectingState);

  const shadeOverlayColor = alpha(theme.palette.text.primary, 0.1); // light shade color

  const isProximityAxis = (axis: StrategyCompassXYZAxis) => {
    return (
      axis === StrategyCompassXYZAxis.ProximityToCallWall ||
      axis === StrategyCompassXYZAxis.ProximityToPutWall
    );
  };

  useEffect(() => {
    // it's possible we've fetched data for these symbols already and they are just being re-enabled
    // if so, dont fetch again
    const currSymsFetched = [...data.keys()];
    const newSymsToFetch = difference(syms, currSymsFetched).filter(
      isValidCompassSymbol,
    );
    if (newSymsToFetch.length === 0) {
      return;
    }

    fetchData(newSymsToFetch);
  }, [fetchData, syms, data]);

  const yAxisTitle = StrategyCompassXYZAxisReadableMap.get(yAxis)!;
  const xAxisTitle = StrategyCompassXYZAxisReadableMap.get(xAxis)!;
  const zAxisTitle =
    zAxis == null || zAxis === StrategyCompassXYZAxis.None
      ? null
      : StrategyCompassXYZAxisReadableMap.get(zAxis)!;

  const lineColor = mode === StrategyCompassMode.Compass ? '#000' : '#fff';
  const strokeWidth = mode === StrategyCompassMode.Compass ? 1.5 : 0.5;
  const axisLinesComponent = (
    <>
      <ReferenceLine
        segment={[
          { x: X_MIN - SEGMENT_PADDING, y: Y_MID },
          { x: X_MAX + SEGMENT_PADDING, y: Y_MID },
        ]}
        stroke={lineColor}
        strokeWidth={strokeWidth}
        ifOverflow="hidden"
        label={(_props) => (
          <>
            <SGTooltip title={generateAxisLabelTooltip('high', yAxis)}>
              <text
                x={chartSize.width / 2.0}
                y={isZoomed ? 15 : 20}
                fill={axisTextColor}
                fontSize={12}
                textAnchor="middle"
              >
                {renderLabelText(
                  `${isProximityAxis(yAxis) ? 'Low' : 'High'} ${yAxisTitle}`,
                )}
              </text>
            </SGTooltip>
            <SGTooltip title={generateAxisLabelTooltip('low', yAxis)}>
              <text
                x={chartSize.width / 2.0}
                y={isZoomed ? chartSize.height - 5 : chartSize.height - 20}
                fill={axisTextColor}
                fontSize={12}
                textAnchor="middle"
              >
                {renderLabelText(
                  `${isProximityAxis(yAxis) ? 'High' : 'Low'} ${yAxisTitle}`,
                )}
              </text>
            </SGTooltip>
          </>
        )}
      />
      <ReferenceLine
        segment={[
          { x: X_MID, y: Y_MIN - SEGMENT_PADDING },
          { x: X_MID, y: Y_MAX + SEGMENT_PADDING },
        ]}
        stroke={lineColor}
        strokeWidth={strokeWidth}
        ifOverflow="hidden"
        label={(_props) => (
          <>
            <SGTooltip title={generateAxisLabelTooltip('high', xAxis)}>
              <text
                x={chartSize.width - EDGE_ALLOWED_SPACING}
                y={chartSize.height / 2.0 + (isZoomed ? 0 : 20)}
                fill={axisTextColor}
                fontSize={12}
                textAnchor="middle"
                transform={`rotate(90, ${
                  chartSize.width - EDGE_ALLOWED_SPACING
                }, ${chartSize.height / 2.0})`}
              >
                {renderLabelText(
                  `${isProximityAxis(xAxis) ? 'Low' : 'High'} ${xAxisTitle}`,
                )}
              </text>
            </SGTooltip>

            <SGTooltip title={generateAxisLabelTooltip('low', xAxis)}>
              <text
                x={EDGE_ALLOWED_SPACING}
                y={chartSize.height / 2.0 + (isZoomed ? 0 : 20)}
                fill={axisTextColor}
                fontSize={12}
                textAnchor="middle"
                transform={`rotate(-90, ${EDGE_ALLOWED_SPACING}, ${
                  chartSize.height / 2.0
                })`}
              >
                {renderLabelText(
                  `${isProximityAxis(xAxis) ? 'High' : 'Low'} ${xAxisTitle}`,
                )}
              </text>
            </SGTooltip>
          </>
        )}
      />
    </>
  );

  // Check if a quadrant is visible within the current zoom domain
  const isQuadrantVisible = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  ) => {
    if (!isZoomed) return true;

    // Check if any part of the quadrant is within the zoom domain
    return !(
      (
        x2 < zoomDomain.x[0] || // Quadrant is completely to the left of zoom
        x1 > zoomDomain.x[1] || // Quadrant is completely to the right of zoom
        y2 < zoomDomain.y[0] || // Quadrant is completely below zoom
        y1 > zoomDomain.y[1]
      ) // Quadrant is completely above zoom
    );
  };

  // Calculate the visible portion of a quadrant
  const getVisibleQuadrantCoords = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  ) => {
    if (!isZoomed) return { x1, y1, x2, y2 };

    return {
      x1: Math.max(x1, zoomDomain.x[0]),
      y1: Math.max(y1, zoomDomain.y[0]),
      x2: Math.min(x2, zoomDomain.x[1]),
      y2: Math.min(y2, zoomDomain.y[1]),
    };
  };

  // Calculate label position based on visible quadrant area
  const getQuadrantLabelPosition = (_quadrant: string, visibleCoords: any) => {
    const { x1, y1, x2, y2 } = visibleCoords;
    const centerX = (x1 + x2) / 2;
    const centerY = (y1 + y2) / 2;

    // Map the logical coordinates to screen coordinates
    const screenX =
      (chartSize.width * (centerX - zoomDomain.x[0])) /
      (zoomDomain.x[1] - zoomDomain.x[0]);
    const screenY =
      chartSize.height *
      (1 - (centerY - zoomDomain.y[0]) / (zoomDomain.y[1] - zoomDomain.y[0]));

    return { x: screenX, y: screenY };
  };

  const renderQuadrant = (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    labelLines: string[],
  ) => {
    if (!isQuadrantVisible(x1, y1, x2, y2)) {
      return null;
    }

    const visibleCoords = getVisibleQuadrantCoords(x1, y1, x2, y2);
    const position = getQuadrantLabelPosition('', visibleCoords);

    return (
      <ReferenceArea
        x1={visibleCoords.x1}
        y1={visibleCoords.y1}
        x2={visibleCoords.x2}
        y2={visibleCoords.y2}
        fill={shadeOverlayColor}
        ifOverflow="hidden"
        label={(_props) => (
          <text
            x={position.x}
            y={position.y}
            fill={labelColor}
            fontSize={fontSize}
            textAnchor="middle"
          >
            {createMultilineText(position.x, labelLines)}
          </text>
        )}
      />
    );
  };

  const labeledReferenceAreas = mode === StrategyCompassMode.Compass && (
    <>
      {renderQuadrant(X_MIN, Y_MIN, X_MID, Y_MID, [
        'Cheap Volatility',
        'Upside Potential',
      ])}

      {renderQuadrant(X_MIN, Y_MID, X_MID, Y_MAX, [
        'Expensive Volatility',
        'Upside Potential',
      ])}

      {renderQuadrant(X_MID, Y_MID, X_MAX, Y_MAX, [
        'Expensive Volatility',
        'Downside Potential',
      ])}

      {renderQuadrant(X_MID, Y_MIN, X_MAX, Y_MID, [
        'Cheap Volatility',
        'Downside Potential',
      ])}
    </>
  );

  function renderLabelText(str: string) {
    return chartSize.width > 0 && chartSize.height > 0 ? str : '';
  }

  const createMultilineText = (x: number, lines: string[], lineHeight = 20) => {
    return lines.map((line, i) => (
      <tspan key={i} x={x} dy={i === 0 ? 0 : lineHeight}>
        {line}
      </tspan>
    ));
  };

  const handleZoom = (domain: CompassZoomDomain) => {
    setZoomDomain(domain);
    setIsZoomed(true);
  };

  const handleMouseDown = (e: any) => {
    if (e && e.xValue && e.yValue) {
      setZoomStartPoint({ x: e.xValue, y: e.yValue });
      setIsSelecting(true);
    }
  };

  const handleMouseMove = (e: any) => {
    if (isSelecting && e && e.xValue && e.yValue) {
      setZoomEndPoint({ x: e.xValue, y: e.yValue });
    }
  };

  const handleMouseUp = (e: any) => {
    if (isSelecting && zoomStartPoint && e && e.xValue && e.yValue) {
      const endPoint = { x: e.xValue, y: e.yValue };

      // Only zoom if the selection box is large enough
      const minSelectionSize = 0.01;
      const xDiff = Math.abs(zoomStartPoint.x - endPoint.x);
      const yDiff = Math.abs(zoomStartPoint.y - endPoint.y);

      if (xDiff > minSelectionSize || yDiff > minSelectionSize) {
        handleZoom({
          x: [
            Math.min(zoomStartPoint.x, endPoint.x),
            Math.max(zoomStartPoint.x, endPoint.x),
          ],
          y: [
            Math.min(zoomStartPoint.y, endPoint.y),
            Math.max(zoomStartPoint.y, endPoint.y),
          ],
        });
      }

      setIsSelecting(false);
      setZoomStartPoint(null);
      setZoomEndPoint(null);
    }
  };

  // Render selection box if user is currently selecting an area
  const renderSelectionBox = () => {
    if (isSelecting && zoomStartPoint && zoomEndPoint) {
      return (
        <ReferenceArea
          x1={zoomStartPoint.x}
          y1={zoomStartPoint.y}
          x2={zoomEndPoint.x}
          y2={zoomEndPoint.y}
          fill={theme.palette.primary.main}
          fillOpacity={0.3}
          stroke={theme.palette.primary.main}
          strokeWidth={1}
          strokeOpacity={0.8}
        />
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: isMobile ? '550px' : '100%',
        padding: { xs: '24px', md: '16px' },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 'calc(100% - 50px)',
          position: 'relative',
        }}
      >
        <StrategyCompassControls
          editable={editable}
          loading={loading}
          chartSize={chartSize}
          compassParams={compassParams}
        />
        <ResponsiveContainer
          onResize={(width, height) => setChartSize({ width, height })}
        >
          {equitiesLoading ? (
            <Loader isLoading={true} /> // chart doesnt render correctly as a child of Loader
          ) : (
            <ScatterChart
              ref={chartRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              margin={
                isZoomed
                  ? { top: 25, right: 45, bottom: 25, left: 45 }
                  : { top: 15, right: 35, bottom: 15, left: 35 }
              }
            >
              <XAxis
                type="number"
                dataKey="x"
                name={xAxisTitle}
                hide
                domain={[zoomDomain.x[0] - 0.02, zoomDomain.x[1] + 0.02]}
                allowDataOverflow
              />
              <YAxis
                type="number"
                hide
                dataKey="y"
                name={yAxisTitle}
                domain={[zoomDomain.y[0] - 0.02, zoomDomain.y[1] + 0.02]}
                allowDataOverflow
              />
              {zAxisTitle != null && (
                <ZAxis
                  type="number"
                  dataKey="z"
                  name={zAxisTitle}
                  range={[10, 300]}
                  domain={COMPASS_DEFAULT_ZOOM_DOMAIN_WITH_BUFFER.y}
                />
              )}

              <defs>
                <linearGradient id={'full_gradient'}>
                  <stop
                    offset="0%"
                    stopColor={
                      mode === StrategyCompassMode.Compass
                        ? positiveTrend
                        : theme.palette.compass.freeformBg
                    }
                    stopOpacity={0.45}
                  />
                  <stop
                    offset="100%"
                    stopColor={
                      mode === StrategyCompassMode.Compass
                        ? negTrend
                        : theme.palette.compass.freeformBg
                    }
                    stopOpacity={0.45}
                  />
                </linearGradient>
              </defs>

              <ReferenceArea
                x1={X_MIN}
                y1={Y_MIN}
                x2={X_MAX}
                y2={Y_MAX}
                fill={`url(#full_gradient)`}
                stroke={'rgba(0,0,0,0)'}
                fillOpacity={1}
                strokeWidth={0}
                strokeOpacity={0}
                ifOverflow="hidden"
              />

              {labeledReferenceAreas}

              {axisLinesComponent}

              {renderSelectionBox()}

              <Scatter
                data={visibleChartData}
                fill={theme.palette.compass.pointColor}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey="sym"
                  content={(props: any) => {
                    let x = props.x - 1.75 * props.value.length;
                    let y = props.y - 3;
                    const forceRight = props.x <= EDGE_ALLOWED_SPACING;
                    const forceLeft =
                      props.x >= chartSize.width - EDGE_ALLOWED_SPACING;

                    if (forceLeft || forceRight) {
                      // if point is at the extreme left/right, position label next to point
                      y += 10;
                      x += forceRight ? 17 : -5.5 * props.value.length;
                    }
                    if (props.y <= EDGE_ALLOWED_SPACING) {
                      // if point is at the top, position label below point
                      y += 20;
                    }

                    return (
                      <text
                        x={x}
                        y={y}
                        fontSize={props.value === hoveredSymbol ? 16 : 12}
                        fill={
                          props.value === hoveredSymbol
                            ? theme.palette.compass.pointColor
                            : '#fff'
                        }
                        fontWeight={
                          props.value === hoveredSymbol ? 'boldest' : 'normal'
                        }
                      >
                        {props.value}
                      </text>
                    );
                  }}
                />
              </Scatter>
              <RechartsTooltip
                isAnimationActive={false}
                content={
                  <StrategyCompassTooltip
                    chartData={chartData}
                    symDataMap={data}
                    compassParams={compassParams}
                  />
                }
              />
            </ScatterChart>
          )}
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
