import { atom, DefaultValue, selector } from 'recoil';
import {
  AccountDetails,
  DashAccess,
  hasDashAccess,
  ProductType,
  SG_SETTINGS_STORAGE_KEY,
  SGSettings,
  SubLevel,
  USER_DETAILS_STORAGE_KEY,
  UserDetails,
  Watchlist,
  GK,
  DataExchangeAgreementMap,
  AnonUserDetails,
} from '../types';
import { ColorMode } from '../theme';
import {
  ET,
  getProductAccess,
  getSubscriptionLevel,
  isBBEnvAvailable,
  isZerohedge,
  isBloomberg,
} from '../util';
import { selectedWatchlistsSymbols } from '../util/shared/watchlists';
import { recoilPersist } from 'recoil-persist';
import {
  DEFAULT_POSITIVE_TREND_COLOR,
  DEFAULT_NEGATIVE_TREND_COLOR,
  DEFAULT_AGREEMENT_FORM,
} from '../config';

const { persistAtom } = recoilPersist();

export const loginErrorMessageState = atom<
  string | React.ReactNode | undefined
>({
  key: 'auth-loginErrorMessageState',
  default: undefined,
});

// No-one should be accessing this other than the top-level check in App.tsx
export const uncachedUserDetailsState = atom<UserDetails | null | undefined>({
  key: 'auth-uncachedUserDetailsState',
  default: undefined,
});

export const userGatekeeperState = atom<Record<string, boolean>>({
  key: 'user-userGKs',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const userStreamingCandlesEnabledState = selector<boolean>({
  key: 'user-streamingCandles',
  get: ({ get }) => {
    if (isBloomberg()) {
      return true;
    }
    const userGKs = get(userGatekeeperState);
    return userGKs[GK.StreamingCandles] ?? false;
  },
});

export const _getCachedItem = (key: string) => {
  if (isBBEnvAvailable() || localStorage.getItem(key) == null) {
    return undefined;
  }
  return JSON.parse(localStorage.getItem(key) || '') as any;
};

const _setCachedItem = (key: string, item: any) => {
  if (isBBEnvAvailable()) {
    return;
  }

  if (item == null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(item));
  }
};

export const userDetailsState = selector<UserDetails | undefined>({
  key: 'auth-currentUserDetailsState',
  get: ({ get }) => {
    const uncached = get(uncachedUserDetailsState);
    return (
      uncached ??
      (_getCachedItem(USER_DETAILS_STORAGE_KEY) as UserDetails | undefined)
    );
  },
  set: ({ set }, newVal) => {
    const userDetails: UserDetails | null =
      newVal instanceof DefaultValue ? null : newVal ?? null;
    _setCachedItem(USER_DETAILS_STORAGE_KEY, userDetails);
    set(uncachedUserDetailsState, userDetails);
  },
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
});

export const userSettingsState = selector<Partial<SGSettings>>({
  key: 'hiro-sgSettingsState',
  get: ({ get }) => {
    const uncached = get(uncachedUserSettingsState);
    return (
      uncached ??
      (_getCachedItem(SG_SETTINGS_STORAGE_KEY) as
        | Partial<SGSettings>
        | undefined) ??
      {}
    );
  },
  set: ({ set }, newVal) => {
    const settings: Partial<SGSettings> =
      newVal instanceof DefaultValue ? {} : newVal ?? {};
    _setCachedItem(SG_SETTINGS_STORAGE_KEY, settings);
    set(uncachedUserSettingsState, settings);
  },
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
});

export const uncachedUserSettingsState = atom<Partial<SGSettings> | undefined>({
  key: 'hiro-sgUncachedSettingsState',
  default: undefined,
});

export const loginLoadingState = atom<boolean>({
  key: 'auth-loginLoadingState',
  default: false,
});

export const watchlistsState = atom<Watchlist[] | undefined>({
  key: 'user-watchlistState',
  default: undefined,
});

export const selectedWatchlistState = selector<string[]>({
  key: 'user-selectedWatchlistState',
  get: ({ get }) => {
    const watchlistsArr = get(watchlistsState);
    const settings = get(userSettingsState);
    const watchlists = watchlistsArr ?? [];
    const selectedIds =
      settings.selectedWatchlistIds ?? watchlists.map((w) => w.id);
    return selectedWatchlistsSymbols(watchlists, selectedIds);
  },
});

export const savePreferencesLoadingState = atom<boolean>({
  key: 'auth-savePreferencesLoadingState',
  default: false,
});

export const accountDetailsState = selector<AccountDetails | undefined>({
  key: 'user-accountDetailsState',
  get: ({ get }) => {
    const user = get(userDetailsState);
    return !!user
      ? {
          first_name: user?.firstName || '',
          last_name: user?.lastName || '',
          email: user?.email || '',
        }
      : undefined;
  },
});

export const positiveTrendColorState = selector<string>({
  key: 'user-positiveTrendColorState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    if (settings?.colors?.positiveTrend != null) {
      return settings.colors.positiveTrend;
    }
    const user = get(userDetailsState);
    const userColor = user?.wordpress?.profile?.mepr_positive_trend_color;
    // userColor can be the empty string, ''.  Test against both that and null
    return !!userColor ? userColor : DEFAULT_POSITIVE_TREND_COLOR;
  },
});

export const negativeTrendColorState = selector<string>({
  key: 'user-negativeTrendColorState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    if (settings?.colors?.negativeTrend != null) {
      return settings.colors.negativeTrend;
    }
    const user = get(userDetailsState);
    const userColor = user?.wordpress?.profile?.mepr_negative_trend_color;
    // userColor can be the empty string, ''.  Test against both that and null
    return !!userColor ? userColor : DEFAULT_NEGATIVE_TREND_COLOR;
  },
});

export const colorModeState = selector<ColorMode>({
  key: 'user-colorModeState',
  get: ({ get }) => {
    const isZerohedgeOrEarnings =
      isZerohedge() || /earnings/.test(window.location.pathname);
    if (isZerohedgeOrEarnings) {
      return ColorMode.LIGHT;
    }

    const settings = get(userSettingsState);
    if (settings?.colors?.colorMode != null) {
      return settings.colors.colorMode as ColorMode;
    }

    const user = get(userDetailsState);
    if (
      !user?.wordpress?.profile ||
      !Object.values(ColorMode).includes(
        user!.wordpress!.profile.mepr_color_mode as ColorMode,
      )
    ) {
      return ColorMode.DARK; // default
    }
    return user!.wordpress!.profile.mepr_color_mode as ColorMode;
  },
});

export const userSubLevelState = selector<SubLevel>({
  key: 'user-subscriptionLevel',
  get: ({ get }) => {
    const user = get(userDetailsState);
    return getSubscriptionLevel(user);
  },
});

export const userDashAccessState = selector<DashAccess>({
  key: 'user-dashAccess',
  get: ({ get }) => {
    const user = get(userDetailsState);
    return (
      (user?.wordpress?.profile.mepr_modern_dashboard_access as DashAccess) ??
      DashAccess.DEFAULT
    );
  },
});

export const hasInternalDashAccessState = selector<boolean>({
  key: 'user-hasInternalDashAccess',
  get: ({ get }) => {
    const userDash = get(userDashAccessState);
    return hasDashAccess(userDash, DashAccess.INTERNAL);
  },
});

export const hasBetaDashAccessState = selector<boolean>({
  key: 'user-hasBetaDashAccess',
  get: ({ get }) => {
    const userDash = get(userDashAccessState);
    return hasDashAccess(userDash, DashAccess.BETA);
  },
});

export const productAccessState = selector<ProductType[]>({
  key: 'user-productAccessState',
  get: ({ get }) => {
    const userDetails = get(userDetailsState);
    return getProductAccess(userDetails);
  },
});

export const playAlertAudioState = selector<boolean>({
  key: 'user-playAlertAudioState',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings.playAlertAudio ?? false;
  },
});

export const timezoneState = selector<string>({
  key: 'user-timezone',
  get: ({ get }) => {
    const settings = get(userSettingsState);
    return settings.timezone ?? ET;
  },
});

export const userIsLoggedInState = selector<boolean>({
  key: 'user-isLoggedInState',
  get: ({ get }) => {
    const userDetails = get(userDetailsState);
    return userDetails != null;
  },
});

export const anonUserDetails = atom<AnonUserDetails>({
  key: 'user-anonUserDetails',
  default: {
    firstName: '',
    lastName: '',
    email: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const isInstitutionalFormState = atom<DataExchangeAgreementMap>({
  key: 'user-isInstitutionalFormState',
  default: DEFAULT_AGREEMENT_FORM,
  effects_UNSTABLE: [persistAtom],
});

export const isInstitutionalLocalState = atom<boolean | null>({
  key: 'user-isInstitutionalLocalState',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
