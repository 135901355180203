import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HistoryTable } from '../EquityhubTable/HistoryTable';
import { SkewGraph } from './SkewGraph';
import { RealTimeGraph } from './RealTimeGraph';
import { PutCallImpactGraph } from './PutCallImpactGraph';
import { CompositeViewGraph } from './CompositeViewGraph';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isMobileState,
  newModelPcTypeState,
  putCallImpactInitialDataState,
  putCallImpactZoomConfigState,
  realTimeChartZoomConfigState,
  realTimeInitialDataState,
  rrChartZoomConfigState,
  rrInitialDataState,
  selectedEquitySymbolState,
  termStructureChartDataState,
  termStructureChartZoomConfigState,
  userDetailsState,
  volSkewChartDataState,
  volSkewChartZoomConfigState,
} from '../../../states';
import { InfoButton, UpsellModal, ZoomOutButton } from '../../shared';
import { RiskReversalGraph } from './RiskReversalGraph';
import { getSubscriptionLevel, updateSearch } from '../../../util';
import { SGTooltip } from '../../core';
import { SymbolTabs } from '../../hiro/HiroChart/controls/SymbolTabs';
import { DEFAULT_BRUSH_ZOOM_CONFIG } from '../../../config';
import { IVOL_UPSELL } from '../../../config/iVol';
import useAuth from '../../../hooks/auth/useAuth';
import { IVolTab, ProductType, TAB_KEYS, TABS, SubLevel } from '../../../types';
import { FixedStrikeTable } from '../../implied_vol/FixedStrikeMatrix';
import { FixedStrikeTableSettings } from '../../implied_vol/FixedStrikeMatrix/FixedStrikeTableControls';
import { TermStructureChartSettings } from '../../implied_vol/TermStructure';
import TermStructureChart from '../../implied_vol/TermStructure/TermStructureChart';
import { VolSkewChartSettings } from '../../implied_vol/VolSkew';
import VolSkewChart from '../../implied_vol/VolSkew/VolSkewChart';
import { DownloadChartButton } from '../../shared/DownloadChartButton';
import PanelFullscreenButton from 'components/shared/PanelFullscreenButton';
import TabbedContent from 'components/shared/TabbedContent';
import { TabItem } from 'types/shared';

const ivolChartTabs = [
  IVolTab.TermStructure as string,
  IVolTab.VolSkew as string,
];

const ivolTabs = [IVolTab.FixedStrikeMatrix as string, ...ivolChartTabs];

export const EquityGraph = () => {
  const [upsellOpen, setUpsellOpen] = useState<boolean>(false);
  const theme = useTheme();
  const tsRef = useRef<HTMLDivElement | null>(null);
  const vsRef = useRef<HTMLDivElement | null>(null);
  const [zoomedIn, setZoomedIn] = useState<boolean>(false);
  const [searchParams, setSearch] = useSearchParams();
  const userDetails = useRecoilValue(userDetailsState);
  const [newModelPcType, setNewModelPcType] =
    useRecoilState(newModelPcTypeState);

  const { hasAccessToProduct } = useAuth();
  const hasImpliedVolAccess = hasAccessToProduct(ProductType.IMPLIED_VOL);
  const membership = useMemo(
    () => getSubscriptionLevel(userDetails),
    [userDetails],
  );

  const currentTab = useMemo(
    () => searchParams.get('tab') ?? 'composite',
    [searchParams],
  );

  useEffect(() => {
    if (currentTab == null || !TAB_KEYS.has(currentTab)) {
      setSearch(updateSearch({ tab: 'composite' }));
    }
  }, [currentTab, setSearch]);

  useEffect(() => {
    if (ivolTabs.includes(currentTab) && !hasImpliedVolAccess) {
      setUpsellOpen(true);
    }
  }, [currentTab, hasImpliedVolAccess]);

  const handleChange = (tab: string) => {
    if (ivolTabs.includes(tab) && !hasImpliedVolAccess) {
      setUpsellOpen(true);
    } else {
      setZoomedIn(false);
      setSearch(updateSearch({ tab }));
    }
  };

  const selectedSymbol = useRecoilValue(selectedEquitySymbolState);
  const [showKeyLevels, setShowKeyLevels] = useState(true);
  const isMobile = useRecoilValue(isMobileState);
  const [realTimeZoomConfig, setRealTimeZoomConfig] = useRecoilState(
    realTimeChartZoomConfigState,
  );
  const realTimeInitialData = useRecoilValue(realTimeInitialDataState);
  const [putCallImpactZoomConfig, setPutCallImpactZoomConfig] = useRecoilState(
    putCallImpactZoomConfigState,
  );
  const putCallImpactInitialData = useRecoilValue(
    putCallImpactInitialDataState,
  );
  const [rrImpactZoomConfig, setRRImpactZoomConfig] = useRecoilState(
    rrChartZoomConfigState,
  );
  const rrImpactInitialData = useRecoilValue(rrInitialDataState);
  const pcImpactInitialData = useRecoilValue(putCallImpactInitialDataState);

  const [termStructureChartZoomConfig, setTermStructureChartZoomConfig] =
    useRecoilState(termStructureChartZoomConfigState);
  const termStructureChartData = useRecoilValue(termStructureChartDataState);
  const [volSkewChartZoomConfig, setVolSkewChartZoomConfig] = useRecoilState(
    volSkewChartZoomConfigState,
  );
  const volSkewChartData = useRecoilValue(volSkewChartDataState);

  const zoomButton = useMemo(() => {
    switch (currentTab) {
      case 'live_price':
        return (
          <ZoomOutButton
            zoomConfig={realTimeZoomConfig}
            setZoomConfig={setRealTimeZoomConfig}
            initialData={realTimeInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: realTimeInitialData.length - 1,
            }}
          />
        );
      case 'pc_impact':
        return (
          <ZoomOutButton
            zoomConfig={putCallImpactZoomConfig}
            setZoomConfig={setPutCallImpactZoomConfig}
            initialData={putCallImpactInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: pcImpactInitialData.length - 1,
            }}
          />
        );
      case 'rr':
        return (
          <ZoomOutButton
            zoomConfig={rrImpactZoomConfig}
            setZoomConfig={setRRImpactZoomConfig}
            initialData={rrImpactInitialData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: rrImpactInitialData.length - 1,
            }}
          />
        );
      case IVolTab.TermStructure:
        return (
          <ZoomOutButton
            key="zoom"
            zoomConfig={termStructureChartZoomConfig}
            setZoomConfig={setTermStructureChartZoomConfig}
            initialData={termStructureChartData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: termStructureChartData.length - 1,
            }}
          />
        );
      case IVolTab.VolSkew:
        return (
          <ZoomOutButton
            key="zoom"
            zoomConfig={volSkewChartZoomConfig}
            setZoomConfig={setVolSkewChartZoomConfig}
            initialData={volSkewChartData}
            overrideDefault={{
              leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
              rightIdx: volSkewChartData.length - 1,
            }}
          />
        );
      default:
        return null;
    }
  }, [
    currentTab,
    realTimeZoomConfig,
    setRealTimeZoomConfig,
    realTimeInitialData,
    putCallImpactZoomConfig,
    setPutCallImpactZoomConfig,
    putCallImpactInitialData,
    rrImpactZoomConfig,
    setRRImpactZoomConfig,
    rrImpactInitialData,
    termStructureChartZoomConfig,
    setTermStructureChartZoomConfig,
    termStructureChartData,
    volSkewChartZoomConfig,
    setVolSkewChartZoomConfig,
    volSkewChartData,
  ]);

  const iVolSettingsBtn = useMemo(() => {
    switch (currentTab) {
      case IVolTab.FixedStrikeMatrix:
        return (
          <FixedStrikeTableSettings
            key="fixed-strike-table-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      case IVolTab.TermStructure:
        return (
          <TermStructureChartSettings
            key="term-structure-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      case IVolTab.VolSkew:
        return (
          <VolSkewChartSettings
            key="volatility-skew-settings"
            selectedSym={selectedSymbol ?? ''}
          />
        );
      default:
        return null;
    }
  }, [currentTab, selectedSymbol]);

  const downloadBtn = useMemo(() => {
    switch (currentTab) {
      case IVolTab.TermStructure:
        return (
          <DownloadChartButton
            chartRef={tsRef}
            selectedSym={selectedSymbol ?? ''}
            chartName="Term-Structure"
          />
        );
      case IVolTab.VolSkew:
        return (
          <DownloadChartButton
            chartRef={vsRef}
            selectedSym={selectedSymbol ?? ''}
            chartName="Volatility-Skew"
          />
        );
      default:
        return null;
    }
  }, [currentTab, selectedSymbol]);

  const getTabActions = useCallback(
    (tabKey: string) => {
      const actions = [];

      if (!['skew', 'history', ...ivolTabs].includes(tabKey)) {
        actions.push(
          <FormControlLabel
            sx={{
              width: '120px',
              marginLeft: !isMobile ? '4px' : 0,
            }}
            control={
              <Switch
                checked={showKeyLevels}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setShowKeyLevels(event.target.checked)
                }
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: isMobile ? 11 : 13,
                }}
              >
                Show key levels
              </Typography>
            }
            labelPlacement={isMobile ? 'top' : 'end'}
          />,
        );
      }

      if (!['history'].includes(tabKey)) {
        actions.push(<InfoButton key={`${tabKey}-info`} articleKey={tabKey} />);
      }

      if (ivolChartTabs.includes(tabKey)) {
        actions.push(downloadBtn);
      }

      const zoomButtonElement =
        zoomedIn || !zoomButton ? (
          zoomButton
        ) : (
          <SGTooltip title="Drag on the chart to zoom in" placement="top">
            {zoomButton}
          </SGTooltip>
        );

      if (zoomButtonElement) {
        actions.push(zoomButtonElement);
      }

      return actions.filter(Boolean);
    },
    [
      isMobile,
      showKeyLevels,
      setShowKeyLevels,
      downloadBtn,
      zoomedIn,
      zoomButton,
    ],
  );

  const staticActions = [
    ivolTabs.includes(currentTab) ? iVolSettingsBtn : null,
    <PanelFullscreenButton panelType="main" />,
  ].filter(Boolean);

  const filteredTABS = Object.keys(TABS)
    .filter((key) => !(membership === SubLevel.ALPHA && key === 'skew'))
    .reduce((obj, key) => {
      obj[key] = TABS[key];
      return obj;
    }, {} as { [key: string]: string });

  const tabs: TabItem[] = useMemo(
    () =>
      Object.entries(filteredTABS).map(([key, label]) => ({
        label,
        value: key,
        content: (
          <>
            {key === 'composite' && (
              <CompositeViewGraph showKeyLevels={showKeyLevels} />
            )}
            {key === 'live_price' && <RealTimeGraph showKeyLevels={false} />}
            {key === 'skew' && <SkewGraph />}
            {key === 'pc_impact' && (
              <PutCallImpactGraph showKeyLevels={false} />
            )}
            {key === 'rr' && <RiskReversalGraph />}
            {key === IVolTab.FixedStrikeMatrix && (
              <FixedStrikeTable selectedSym={selectedSymbol ?? ''} />
            )}
            {key === IVolTab.TermStructure && (
              <TermStructureChart
                ref={tsRef}
                selectedSym={selectedSymbol ?? ''}
              />
            )}
            {key === IVolTab.VolSkew && (
              <VolSkewChart ref={vsRef} selectedSym={selectedSymbol ?? ''} />
            )}
            {key === 'history' && <HistoryTable />}
          </>
        ),
        actions: getTabActions(key),
      })),
    [filteredTABS, showKeyLevels, selectedSymbol, tsRef, vsRef, getTabActions],
  );

  return (
    <>
      <TabbedContent
        currentSym={selectedSymbol}
        tabs={tabs}
        defaultTab={currentTab}
        staticActions={staticActions}
        onChange={handleChange}
      />

      <Box sx={{ paddingTop: '6px' }}>
        <SymbolTabs productType={ProductType.EQUITYHUB} />
      </Box>

      <UpsellModal
        open={upsellOpen}
        setOpen={setUpsellOpen}
        title={IVOL_UPSELL.title}
        subtitle={IVOL_UPSELL.subtitle}
        items={IVOL_UPSELL.items}
      />
    </>
  );
};
