import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import {
  Stack,
  Typography,
  Button,
  SelectChangeEvent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  TextField,
  Autocomplete,
  InputAdornment,
  useMediaQuery,
  Popper,
  autocompleteClasses,
} from '@mui/material';
import { CompassData } from '../../../hooks/scanners/useCompassData';
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  displayValue,
  formatAsCurrency,
  getEquitiesForScanner,
} from '../../../util';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import OptionsDropdownSelector from '../../shared/OptionsDropdownSelector';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  compassHiddenSymbolsState,
  compassHoveredSymbolState,
} from '../../../states/compass';
import {
  isMobileState,
  synthesizedEquitiesState,
  watchlistsState,
} from '../../../states';
import { Equity, Scanner, Watchlist } from 'types';
import { SG_SPECIAL_SCANNERS } from 'config';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EditWatchlists } from 'components/watchlist/EditWatchlists';
import useToast from 'hooks/useToast';
import StockIcon from 'components/StockIcon';
import SearchIcon from '@mui/icons-material/Search';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import React from 'react';

type StrategyCompassEditSymbolsProps = {
  compassData: CompassData;
  compassParams: CompassParams;
};

const ALL_SCANNER_OPTIONS = SG_SPECIAL_SCANNERS.map((scObj) => ({
  label: scObj.label.name,
  value: scObj.scanner,
}));

const MAX_SYMBOLS = 50;
const LISTBOX_PADDING = 8; // px

// Context for outer element props
const OuterElementContext = React.createContext({});

// Outer element type for the virtualized list
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Hook to reset cache when data changes
function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Render row function for the virtualized list
function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" {...dataSet.props} noWrap style={inlineStyle}>
      {dataSet.option}
    </Typography>
  );
}

// Styled Popper component for Autocomplete
const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  },
  [`& .${autocompleteClasses.option}`]: {
    padding: '8px 12px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    '&.Mui-focused': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
  },
}));

// Virtualized listbox component for Autocomplete
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: Array<{ props: any; option: string }> = [];

  // Map children to flat array
  React.Children.forEach(children as React.ReactElement[], (child) => {
    if (child) {
      itemData.push({
        props: child.props,
        option: child.props.children,
      });
    }
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 48 : 56; // Increased height for better visibility

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export const StrategyCompassEditSymbols = ({
  compassParams,
  compassData,
}: StrategyCompassEditSymbolsProps) => {
  const watchlists = useRecoilValue(watchlistsState);
  const { openToast } = useToast();

  const { chartData, visibleChartData } = compassData;
  const {
    setSyms,
    syms,
    zAxisDataKey,
    xAxis,
    yAxis,
    zAxis,
    setActiveWatchlistIdsState,
  } = compassParams;

  const equities = useRecoilValue(synthesizedEquitiesState);
  const [showEditWatchlistModal, setShowEditWatchlistModal] = useState(false);
  const [maxSymbolsReached, setMaxSymbolsReached] = useState(false);
  const [symbolsWereTruncated, setSymbolsWereTruncated] = useState(false);

  const theme = useTheme();

  const [hiddenSymbols, setHiddenSymbols] = useRecoilState(
    compassHiddenSymbolsState,
  );
  const setHoveredSymbol = useSetRecoilState(compassHoveredSymbolState);
  const isMobile = useRecoilValue(isMobileState);

  // Get all available symbols from manifest
  const allAvailableSymbols = useMemo(
    () => Array.from(equities.values()).map((eq) => eq.sym),
    [equities],
  );

  // Get equity details for autocomplete
  const equityDetails = useMemo(() => {
    const details = new Map<string, { name: string; sym: string }>();
    Array.from(equities.values()).forEach((eq) => {
      details.set(eq.sym, { name: eq.name || '', sym: eq.sym });
    });
    return details;
  }, [equities]);

  const toggleHiddenSymbols = (sym: string) => {
    if (hiddenSymbols.has(sym)) {
      showSymbols([sym]);
    } else {
      hideSymbols([sym]);
    }
  };

  const hideSymbols = (syms: string[]) => {
    setHiddenSymbols(new Set([...hiddenSymbols].concat(syms)));
  };

  const showSymbols = (syms: string[]) => {
    const setArr = [...hiddenSymbols].filter((s) => !syms.includes(s));
    setHiddenSymbols(new Set(setArr));
  };

  const deleteSym = (sym: string) => {
    setSyms(syms.filter((s) => s !== sym));
    setHiddenSymbols(
      (hiddenSyms) => new Set([...hiddenSyms].filter((s) => s !== sym)),
    );
    setMaxSymbolsReached(false);
    setSymbolsWereTruncated(false);
  };

  const deleteAllSyms = () => {
    setSyms([]);
    setHiddenSymbols(new Set());
    setActiveWatchlistIdsState([]);
    setMaxSymbolsReached(false);
    setSymbolsWereTruncated(false);
  };

  const handleSymbolSearch = (event: any, newValue: any) => {
    if (!newValue) return;

    // Check if symbol is already in the list
    if (syms.includes(newValue)) {
      openToast({
        message: `${newValue} is already in your symbols list`,
        type: 'error',
      });
      return;
    }

    // Check if we've reached the maximum number of symbols
    if (syms.length >= MAX_SYMBOLS) {
      setMaxSymbolsReached(true);
      openToast({
        message: `Maximum of ${MAX_SYMBOLS} symbols reached. Delete some symbols to add more.`,
        type: 'warning',
      });
      return;
    }

    // Add the new symbol at the beginning of the list
    setSyms([newValue, ...syms]);
  };

  const onScannerSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedScannerIds = event.target.value as Scanner[];
      const resultData = [...equities.values()];

      // Get all possible scanner symbols
      const allScannerSymbols = new Set(
        ALL_SCANNER_OPTIONS.flatMap((sc) =>
          getEquitiesForScanner(sc.value, resultData),
        ).map((eq) => eq.sym),
      );

      // Get symbols from currently selected scanners
      const selectedScannerSymbols = new Set(
        selectedScannerIds
          .flatMap((scannerId) => getEquitiesForScanner(scannerId, resultData))
          .map((equity: Equity) => equity.sym),
      );

      // Keep symbols that are either:
      // 1. Not from any scanner (preserve non-scanner symbols)
      // 2. From currently selected scanners
      const updatedSymbols = [
        ...Array.from(selectedScannerSymbols),
        ...syms.filter(
          (sym) =>
            !allScannerSymbols.has(sym) || selectedScannerSymbols.has(sym),
        ),
      ];

      const uniqueSymbols = Array.from(new Set(updatedSymbols));

      if (uniqueSymbols.length > MAX_SYMBOLS) {
        setSyms(uniqueSymbols.slice(0, MAX_SYMBOLS));
        setMaxSymbolsReached(true);
        setSymbolsWereTruncated(true);
      } else {
        setSyms(uniqueSymbols);
        setSymbolsWereTruncated(false);
      }
    },
    [equities, syms],
  );

  useEffect(() => {
    // Check if symbols exceed the maximum limit
    if (syms.length > MAX_SYMBOLS) {
      // Truncate symbols to the maximum allowed
      setSyms(syms.slice(0, MAX_SYMBOLS));
      setMaxSymbolsReached(true);
      setSymbolsWereTruncated(true);
    } else if (syms.length === MAX_SYMBOLS) {
      setMaxSymbolsReached(true);
      setSymbolsWereTruncated(false);
    } else {
      setMaxSymbolsReached(false);
      setSymbolsWereTruncated(false);
    }
  }, [syms, setSyms]);

  const selectedScanners = useMemo(() => {
    const resultData = [...equities.values()];

    // If syms is empty, no scanners should be selected
    if (syms.length === 0) {
      return [];
    }

    return ALL_SCANNER_OPTIONS.filter((sc) => {
      const scannerEquities = getEquitiesForScanner(sc.value, resultData);
      // Only select scanners where at least one of their symbols is in syms
      // AND there are symbols in the scanner
      return (
        scannerEquities.length > 0 &&
        scannerEquities.some((eq) => syms.includes(eq.sym))
      );
    }).map((sc) => sc.value);
  }, [syms, equities]);

  const watchlistOptions = useMemo(
    () =>
      watchlists?.map((w) => ({
        label: w.name,
        value: `${w.id!}`,
      })) ?? [],
    [watchlists],
  );

  const onEditWatchlists = () => {
    setShowEditWatchlistModal(true);
  };

  const selectedWatchlists = useMemo(() => {
    return watchlists
      ?.filter((wl: Watchlist) =>
        wl.symbols.every((sym: string) => syms.includes(sym)),
      )
      .map((wl: Watchlist) => wl.id!.toString());
  }, [watchlists, syms]);

  const onWatchlistSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedWatchlistIds = (event.target.value as string[]).map(Number);

      // Get all symbols from all watchlists
      const allWatchlistSymbols = new Set(
        watchlists?.flatMap((wl) => wl.symbols) ?? [],
      );

      // Get symbols from currently selected watchlists
      const selectedWatchlistSymbols = new Set(
        watchlists
          ?.filter((wl) => selectedWatchlistIds.includes(wl.id!))
          .flatMap((wl) => wl.symbols) ?? [],
      );

      // Keep symbols that are either:
      // 1. Not from any watchlist (preserve non-watchlist symbols)
      // 2. From currently selected watchlists
      const updatedSymbols = [
        ...Array.from(selectedWatchlistSymbols),
        ...syms.filter(
          (sym) =>
            !allWatchlistSymbols.has(sym) || selectedWatchlistSymbols.has(sym),
        ),
      ];

      const uniqueSymbols = Array.from(new Set(updatedSymbols));

      if (uniqueSymbols.length > MAX_SYMBOLS) {
        setSyms(uniqueSymbols.slice(0, MAX_SYMBOLS));
        setMaxSymbolsReached(true);
        setSymbolsWereTruncated(true);
      } else {
        setSyms(uniqueSymbols);
        setSymbolsWereTruncated(false);
      }
      setActiveWatchlistIdsState(selectedWatchlistIds);
    },
    [watchlists, syms, setSyms, setActiveWatchlistIdsState],
  );

  return (
    <Stack
      sx={{
        width: 1,
        maxHeight: isMobile ? '500px' : undefined,
        gap: '12px',
        padding: '12px',
        height: '100%',
      }}
    >
      <Autocomplete
        disableListWrap
        options={allAvailableSymbols}
        onChange={handleSymbolSearch}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for a symbol..."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: theme.palette.text.secondary }} />
                </InputAdornment>
              ),
            }}
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              width: '100%',
              '& fieldset': {
                borderColor: alpha(theme.palette.primary.main, 0.35),
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
              },
            }}
          />
        )}
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        renderOption={(props, option) => {
          const equity = equityDetails.get(option);
          return (
            <li {...props} key={option} style={{ padding: '8px 12px' }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%', gap: 2 }}
              >
                <StockIcon symbol={option} />
                <Stack sx={{ ml: '12px !important' }}>
                  <Typography fontWeight="bold">{option}</Typography>
                  {equity?.name && (
                    <Typography variant="caption" color="text.secondary">
                      {equity.name}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </li>
          );
        }}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.default,
          },
          '& .MuiAutocomplete-paper': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[3],
            border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
          },
        }}
      />

      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 8,
        }}
      >
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '12px',
            width: '75px',
          }}
          onClick={() =>
            visibleChartData.length === 0
              ? showSymbols(syms)
              : hideSymbols(syms)
          }
          disabled={chartData.length === 0}
        >
          {visibleChartData.length === 0 ? 'Show All' : 'Hide All'}
        </Button>
        <OptionsDropdownSelector
          label="Watchlists"
          value={selectedWatchlists ?? []}
          options={watchlistOptions}
          isMultiple
          onChange={onWatchlistSelectChange}
          sx={{ maxWidth: '100%' }}
          onEdit={onEditWatchlists}
          isEditable
          editLabel="Edit Watchlists"
        />
        <OptionsDropdownSelector
          label="Scanners"
          value={selectedScanners ?? []}
          options={ALL_SCANNER_OPTIONS}
          isMultiple
          onChange={onScannerSelectChange}
          sx={{ maxWidth: '100%' }}
        />
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '12px',
            width: '75px',
          }}
          onClick={deleteAllSyms}
        >
          Clear All
        </Button>
      </Stack>

      {maxSymbolsReached && (
        <Alert
          severity="warning"
          sx={{
            my: 1,
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: 'text.primary',
          }}
        >
          {symbolsWereTruncated
            ? `Maximum of ${MAX_SYMBOLS} symbols reached. Some symbols were removed to stay within the limit.`
            : `Maximum of ${MAX_SYMBOLS} symbols reached. Delete some symbols to add more.`}
        </Alert>
      )}

      {chartData.length === 0 ? (
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Stack
            sx={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              color: 'text.secondary',
            }}
          >
            <Typography variant="h6">No Equities Found</Typography>
            <Typography variant="body2">
              Select a watchlist or scanner to view equities
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 'calc(100% - 50px)',
            overflowY: 'auto',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Symbol</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                  Price
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                  X
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                  Y
                </TableCell>
                {zAxisDataKey != null && (
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    Z
                  </TableCell>
                )}
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chartData.map((data) => {
                const backgroundColor = hiddenSymbols.has(data.sym)
                  ? 'inherit'
                  : alpha(theme.palette.primary.main, 0.1);

                return (
                  <TableRow
                    key={data.sym}
                    sx={{
                      backgroundColor,
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.25),
                        cursor: 'pointer',
                      },
                      '&:last-child td, &:last-child th': { border: 0 },
                      '& td, & th': {
                        borderBottom: `1px solid ${alpha(
                          theme.palette.primary.main,
                          0.5,
                        )}`,
                      },
                    }}
                    onMouseEnter={() => setHoveredSymbol(data.sym)}
                    onMouseLeave={() => setHoveredSymbol(undefined)}
                    onClick={() => toggleHiddenSymbols(data.sym)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => toggleHiddenSymbols(data.sym)}
                      sx={{
                        fontSize: { xs: 12, md: 14 },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100px',
                      }}
                    >
                      <Stack
                        flex={1}
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <StockIcon symbol={data.sym} />
                        <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>
                          {data.sym}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      {formatAsCurrency(data.symData.price)}
                    </TableCell>
                    <TableCell align="right">
                      {displayValue(data.unroundedX, xAxis)}
                    </TableCell>
                    <TableCell align="right">
                      {displayValue(data.unroundedY, yAxis)}
                    </TableCell>
                    {data.unroundedZ != null && (
                      <TableCell align="right">
                        {displayValue(data.unroundedZ, zAxis!)}
                      </TableCell>
                    )}
                    <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleHiddenSymbols(data.sym);
                        }}
                        color="primary"
                        sx={{ p: 0.5 }}
                      >
                        {hiddenSymbols.has(data.sym) ? (
                          <VisibilityOffIcon fontSize="small" />
                        ) : (
                          <VisibilityIcon fontSize="small" />
                        )}
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteSym(data.sym);
                        }}
                        color="primary"
                        sx={{ p: 0.5 }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <EditWatchlists
        onClose={() => setShowEditWatchlistModal(false)}
        open={showEditWatchlistModal}
        modal={true}
        // toast needs to be displayed after the modal disappears
        openToast={(toastData) => setTimeout(() => openToast(toastData), 1000)}
      />
    </Stack>
  );
};
