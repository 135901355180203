import React, { useRef, useState, createContext, useContext } from 'react';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
  ImperativePanelHandle,
} from 'react-resizable-panels';
import { ProductType } from 'types/shared';
import PanelFullscreenButton from 'components/shared/PanelFullscreenButton';
import PanelFoldButton from 'components/shared/PanelFoldButton';

interface DashboardLayoutProps {
  dashboardId: ProductType;
  mainContent: React.ReactNode;
  sidebarTopContent: React.ReactNode;
  sidebarBottomContent?: React.ReactNode; // optionally make sidebar into two boxes
  bottomContent: React.ReactNode;
  bottomContentHeaderName: string;
  customDefaultSizes?: {
    main: number;
    sidebar: number;
    bottom: number;
  };
}

interface CollapsedState {
  sidebar: boolean;
  bottom: boolean;
  top: boolean;
}

interface DashboardContextType {
  isMainFullscreen: boolean;
  isBottomFullscreen: boolean;
  isTopCollapsed: boolean;
  isBottomCollapsed: boolean;
  toggleMainFullscreen: () => void;
  toggleBottomFullscreen: () => void;
  toggleExpandBottom: () => void;
  toggleExpandTop: () => void;
  bottomContentHeaderName: string;
}

const DashboardContext = createContext<DashboardContextType | null>(null);

export const useFullscreen = (): DashboardContextType => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useFullscreen must be used within DashboardLayout');
  }
  return context;
};

export const ResizableDashboardLayout: React.FC<DashboardLayoutProps> = ({
  dashboardId,
  mainContent,
  sidebarTopContent,
  sidebarBottomContent,
  bottomContent,
  bottomContentHeaderName,
  customDefaultSizes,
}) => {
  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

  // Panel refs for manipulation
  const topPanelRef = useRef<ImperativePanelHandle>(null);
  const sidebarPanelRef = useRef<ImperativePanelHandle>(null);
  const bottomPanelRef = useRef<ImperativePanelHandle>(null);

  // Track collapsed states
  const [collapsed, setCollapsed] = useState<CollapsedState>({
    sidebar: false,
    bottom: false,
    top: false,
  });

  // Store previous states before entering fullscreen or collapsing
  const previousStates = useRef<CollapsedState | null>(null);

  // Fullscreen control functions
  const toggleMainFullscreen = () => {
    const isFullscreen = collapsed.sidebar && collapsed.bottom;

    if (!isFullscreen) {
      // Store current states before entering fullscreen
      previousStates.current = { ...collapsed };

      // Enter fullscreen
      sidebarPanelRef.current?.collapse();
      bottomPanelRef.current?.collapse();
      setCollapsed((prev) => ({ ...prev, sidebar: true, bottom: true }));
    } else {
      // Restore previous states
      if (previousStates.current) {
        if (!previousStates.current.sidebar) {
          sidebarPanelRef.current?.expand();
        }
        if (!previousStates.current.bottom) {
          bottomPanelRef.current?.expand();
        }
        setCollapsed(previousStates.current);
      }
    }
  };

  const toggleBottomFullscreen = () => {
    const isFullscreen = collapsed.top;

    if (!isFullscreen) {
      previousStates.current = { ...collapsed };
      topPanelRef.current?.collapse();
      setCollapsed((prev) => ({ ...prev, top: true }));
    } else {
      if (previousStates.current) {
        if (!previousStates.current.top) {
          topPanelRef.current?.expand();
        }
        setCollapsed(previousStates.current);
      }
    }
  };

  const toggleExpandBottom = () => {
    if (collapsed.bottom) {
      bottomPanelRef.current?.expand();
      setCollapsed((prev) => ({ ...prev, bottom: false }));
    } else {
      bottomPanelRef.current?.collapse();
      setCollapsed((prev) => ({ ...prev, bottom: true }));
    }
  };

  const toggleExpandTop = () => {
    if (collapsed.top) {
      topPanelRef.current?.expand();
      setCollapsed((prev) => ({ ...prev, top: false }));
    } else {
      topPanelRef.current?.collapse();
      setCollapsed((prev) => ({ ...prev, top: true }));
    }
  };

  const contextValue: DashboardContextType = {
    isMainFullscreen: collapsed.sidebar && collapsed.bottom,
    isBottomFullscreen: collapsed.top,
    isBottomCollapsed: collapsed.bottom,
    isTopCollapsed: collapsed.top,
    toggleMainFullscreen,
    toggleBottomFullscreen,
    toggleExpandBottom,
    toggleExpandTop,
    bottomContentHeaderName,
  };

  return (
    <DashboardContext.Provider value={contextValue}>
      {isLgDown ? (
        <Stack sx={{ gap: 6, width: '100%' }}>
          <Box sx={{ bgcolor: 'background.paper', borderRadius: 2, p: 1 }}>
            {mainContent}
          </Box>
          <Box sx={{ bgcolor: 'background.paper', borderRadius: 2, p: 1 }}>
            {sidebarTopContent}
          </Box>
          {sidebarBottomContent && (
            <Box sx={{ bgcolor: 'background.paper', borderRadius: 2, p: 1 }}>
              {sidebarBottomContent}
            </Box>
          )}
          <Box sx={{ bgcolor: 'background.paper', borderRadius: 2, p: 1 }}>
            {bottomContent}
          </Box>
        </Stack>
      ) : (
        <Box sx={{ height: '100%', paddingBottom: '20px' }}>
          <PanelGroup
            direction="vertical"
            autoSaveId={`${dashboardId}-vertical`}
          >
            <Panel
              ref={topPanelRef}
              collapsible
              defaultSize={customDefaultSizes?.main ?? 65}
              minSize={30}
              onCollapse={() =>
                setCollapsed((prev) => ({ ...prev, top: true }))
              }
              onExpand={() => setCollapsed((prev) => ({ ...prev, top: false }))}
            >
              <PanelGroup
                direction="horizontal"
                autoSaveId={`${dashboardId}-horizontal`}
              >
                <Panel id="main" defaultSize={70} minSize={40}>
                  <Box
                    sx={{
                      height: '100%',
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    {mainContent}
                  </Box>
                </Panel>

                <PanelResizeHandle>
                  <Stack sx={{ gap: 2, alignItems: 'center', height: '100%' }}>
                    <Box
                      sx={{
                        height: '100%',
                        width: '2px',
                        bgcolor: alpha(theme.palette.primary.main, 0.15),
                      }}
                    />
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => {
                        if (collapsed.sidebar) {
                          sidebarPanelRef.current?.expand();
                          setCollapsed((prev) => ({ ...prev, sidebar: false }));
                        } else {
                          sidebarPanelRef.current?.collapse();
                          setCollapsed((prev) => ({ ...prev, sidebar: true }));
                        }
                      }}
                    >
                      {collapsed.sidebar ? (
                        <KeyboardArrowLeftIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </IconButton>
                    <Box
                      sx={{
                        height: '100%',
                        width: '2px',
                        bgcolor: alpha(theme.palette.primary.main, 0.15),
                      }}
                    />
                  </Stack>
                </PanelResizeHandle>

                <Panel
                  ref={sidebarPanelRef}
                  defaultSize={customDefaultSizes?.sidebar ?? 30}
                  minSize={25}
                  collapsible
                  onCollapse={() =>
                    setCollapsed((prev) => ({ ...prev, sidebar: true }))
                  }
                  onExpand={() =>
                    setCollapsed((prev) => ({ ...prev, sidebar: false }))
                  }
                >
                  {sidebarBottomContent ? (
                    <Stack sx={{ gap: 3, height: '100%' }}>
                      <Box
                        sx={{
                          height: 'auto',
                          minHeight: '50%',
                          bgcolor: 'background.paper',
                          borderRadius: 2,
                          overflow: 'hidden',
                          flexGrow: 1,
                          p: 2,
                        }}
                      >
                        {sidebarTopContent}
                      </Box>
                      <Box
                        sx={{
                          height: 'auto',
                          bgcolor: 'background.paper',
                          borderRadius: 2,
                          overflow: 'hidden',
                          p: 2,
                        }}
                      >
                        {sidebarBottomContent}
                      </Box>
                    </Stack>
                  ) : (
                    <Box
                      sx={{
                        height: '100%',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        p: 2,
                      }}
                    >
                      {sidebarTopContent}
                    </Box>
                  )}
                </Panel>
              </PanelGroup>
            </Panel>

            <PanelResizeHandle>
              <Stack
                sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '2px',
                    bgcolor: alpha(theme.palette.primary.main, 0.15),
                  }}
                />
                <IconButton
                  color="primary"
                  size="small"
                  onClick={toggleExpandBottom}
                >
                  <DragHandleIcon />
                </IconButton>
                <Box
                  sx={{
                    width: '100%',
                    height: '2px',
                    bgcolor: alpha(theme.palette.primary.main, 0.15),
                  }}
                />
              </Stack>
            </PanelResizeHandle>

            {collapsed.bottom && (
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  p: '16px',
                }}
              >
                <Stack
                  sx={{
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>{bottomContentHeaderName}</Typography>
                  <Stack sx={{ flexDirection: 'row', gap: 2 }}>
                    <PanelFullscreenButton panelType="bottom" />
                    <PanelFoldButton panelType="bottom" />
                  </Stack>
                </Stack>
              </Box>
            )}

            <Panel
              ref={bottomPanelRef}
              defaultSize={customDefaultSizes?.bottom ?? 35}
              minSize={25}
              collapsible
              onCollapse={() =>
                setCollapsed((prev) => ({ ...prev, bottom: true }))
              }
              onExpand={() =>
                setCollapsed((prev) => ({ ...prev, bottom: false }))
              }
            >
              <Box
                sx={{
                  height: '100%',
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  p: 2,
                  overflow: 'auto',
                }}
              >
                {bottomContent}
              </Box>
            </Panel>
          </PanelGroup>
        </Box>
      )}
    </DashboardContext.Provider>
  );
};
