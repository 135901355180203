import {
  Box,
  Button,
  Collapse,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import StockIcon from 'components/StockIcon';
import { Equity, EquityFieldKey } from 'types';
import { EQUITY_FIELD_LABEL_MAPPING } from 'config/equityhub';
import EquityDetailRow from './EquityDetailRow';
import { formatEquityFieldValue } from 'util/equityhub';
import { alpha, useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { positiveTrendColorState, negativeTrendColorState } from 'states';
import EquityDetailsEditorBtn from './EquityDetailsEditorBtn';

interface EquityDetailsProps {
  equity?: Equity;
  isLoading?: boolean;
  isExpanded?: boolean;
  onToggleExpanded?: () => void;
  equityMainViewFields: EquityFieldKey[];
  equityExpandedViewFields: EquityFieldKey[];
  equityOtherFields: EquityFieldKey[];
  onEditFields: (
    mainViewFields: EquityFieldKey[],
    expandedViewFields: EquityFieldKey[],
  ) => void;
}

const EquityDetails = ({
  equity,
  isLoading,
  isExpanded,
  onToggleExpanded,
  equityMainViewFields,
  equityExpandedViewFields,
  equityOtherFields,
  onEditFields,
}: EquityDetailsProps) => {
  const theme = useTheme();

  const serverPositiveTrendColor: string = useRecoilValue(
    positiveTrendColorState,
  );
  const serverNegativeTrendColor: string = useRecoilValue(
    negativeTrendColorState,
  );

  const getTrendColor = (field: EquityFieldKey, value: number) => {
    if (field === EquityFieldKey.dailyChange && value != null) {
      return value >= 0 ? serverPositiveTrendColor : serverNegativeTrendColor;
    }

    return undefined;
  };

  if (isLoading) {
    return (
      <Stack sx={{ gap: 4, width: '100%', height: '100%', padding: '24px' }}>
        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          <Skeleton variant="circular" width={24} height={24} />
          <Stack sx={{ flex: 1 }}>
            <Skeleton variant="text" width={100} height={24} />
            <Skeleton variant="text" width={200} height={20} />
          </Stack>
        </Stack>
        <Stack sx={{ gap: 2 }}>
          {[...Array(6)].map((_, index) => (
            <Skeleton key={index} width="100%" height={24} />
          ))}
        </Stack>
      </Stack>
    );
  }

  if (!equity) {
    return null;
  }

  return (
    <Stack sx={{ gap: 4, width: '100%', height: '100%', padding: '24px' }}>
      <Stack
        sx={{ flexDirection: 'row', gap: 2, justifyContent: 'space-between' }}
      >
        <Stack sx={{ flexDirection: 'row', gap: 4 }}>
          <StockIcon
            symbol={equity.sym}
            sx={{ width: 24, height: 24, mt: 2 }}
          />
          <Stack>
            <Typography
              sx={{
                fontSize: { xs: 14, md: 16 },
                textTransform: 'uppercase',
              }}
            >
              {equity.sym}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 12, md: 14 },
                color: 'text.secondary',
              }}
            >
              {equity.name}
            </Typography>
          </Stack>
        </Stack>

        <Box>
          <EquityDetailsEditorBtn
            mainViewFields={equityMainViewFields}
            expandedViewFields={equityExpandedViewFields}
            otherFields={equityOtherFields}
            onSave={onEditFields}
            mainViewMaxLimit={10}
          />
        </Box>
      </Stack>
      <Stack sx={{ overflow: 'auto', px: '8px', gap: '4px' }}>
        {equityMainViewFields.map((field) => (
          <EquityDetailRow
            key={field}
            label={EQUITY_FIELD_LABEL_MAPPING[field]}
            value={formatEquityFieldValue(field, equity[field as keyof Equity])}
            valueColor={getTrendColor(
              field,
              equity[field as keyof Equity] as number,
            )}
          />
        ))}

        <Collapse in={isExpanded}>
          {equityExpandedViewFields.map((field) => (
            <EquityDetailRow
              key={field}
              label={EQUITY_FIELD_LABEL_MAPPING[field]}
              value={formatEquityFieldValue(
                field,
                equity[field as keyof Equity],
              )}
            />
          ))}
        </Collapse>
      </Stack>

      {equityExpandedViewFields.length > 0 && (
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            width: '100%',
          }}
        >
          <Box
            sx={{
              flex: 1,
              backgroundColor: alpha(theme.palette.primary.main, 0.25),
              height: '1.5px',
            }}
          />

          <Button
            size="small"
            variant="text"
            color="primary"
            onClick={onToggleExpanded}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              textTransform: 'none',
            }}
          >
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>

          <Box
            sx={{
              flex: 1,
              backgroundColor: alpha(theme.palette.primary.main, 0.25),
              height: '1.5px',
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default EquityDetails;
