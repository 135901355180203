import { RawGreeksObject } from './impliedVol';

export interface Levels {}

export interface TimeSeriesData {
  symbol: string;
  price: number | null;
  priceDelta: number | null;
  values: number[];
}

export enum EquityFieldKey {
  tradeDate = 'trade_date',
  name = 'name',
  sym = 'sym',
  price = 'price',
  dailyChange = 'daily_change',
  prevClose = 'upx',
  volume = 'total_volume',
  wkHigh52 = 'dpi_high52w',
  wkLow52 = 'dpi_low52w',
  earningsDate = 'earnings_utc',
  keyGammaStr = 'keyg',
  keyDeltaStr = 'keyd',
  hedgeWall = 'maxfs',
  callWall = 'cws',
  putWall = 'pws',
  callGamma = 'atmgc',
  putGamma = 'atmgp',
  callDelta = 'atmdc',
  putDelta = 'atmdp',
  nextExpGamma = 'next_exp_g',
  nextExpDelta = 'next_exp_d',
  topGammaExp = 'max_exp_g_date',
  topDeltaExp = 'max_exp_d_date',
  callVol = 'cv',
  putVol = 'pv',
  nextExpCallVol = 'ne_call_volume',
  nextExpPutVol = 'ne_put_volume',
  putCallRatio = 'put_call_ratio',
  putCallVolRatio = 'volume_ratio',
  gammaRatio = 'gamma_ratio',
  deltaRatio = 'delta_ratio',
  neSkew = 'ne_skew',
  skew = 'skew',
  rv30 = 'rv30',
  iv30 = 'atm_iv30',
  ivRank = 'iv_rank',
  garchRank = 'garch_rank',
  skewRank = 'skew_rank',
  optionsImpliedMove = 'options_implied_move',
  dpi = 'dpi',
  dpiVol = 'dpi_volume',
  dpi5day = 'hist_dpi',
  dpi5dayVol = 'hist_dpi_volume',
  callsum = 'callsum',
  putsum = 'putsum',
  minfs = 'minfs',
  largeCoi = 'largeCoi',
  largePoi = 'largePoi',
  totaldelta = 'totaldelta',
  d95ne = 'd95ne',
  d25ne = 'd25ne',
  d95 = 'd95',
  d25 = 'd25',
  strike_list = 'strike_list',
  cg_list = 'cg_list',
  pg_list = 'pg_list',
  mf_list = 'mf_list',
  smf_list = 'smf_list',
  putctrl = 'putctrl',
  activity_factor = 'activity_factor',
  position_factor = 'position_factor',
  call_strikes_list_absg = 'call_strikes_list_absg',
  call_gnot_list_absg = 'call_gnot_list_absg',
  put_strikes_list_absg = 'put_strikes_list_absg',
  put_gnot_list_absg = 'put_gnot_list_absg',
  date = 'date',
  Symbol = 'Symbol',
  dpi_sector = 'dpi_sector',
  live = 'live',
  vrp_scanner = 'vrp_scanner',
  vrp_scanner_high = 'vrp_scanner_high',
  squeeze_scanner = 'squeeze_scanner',
  call_oi = 'call_oi',
  call_gamma_abs = 'call_gamma_abs',
  put_oi = 'put_oi',
  put_gamma_abs = 'put_gamma_abs',
  iv_pct = 'iv_pct',
  cskew_pct = 'cskew_pct',
  pskew_pct = 'pskew_pct',
}

export interface Equity {
  [EquityFieldKey.sym]: string;
  [EquityFieldKey.price]?: number;
  [EquityFieldKey.dailyChange]?: number;
  [EquityFieldKey.name]?: string;
  [EquityFieldKey.tradeDate]: string;
  [EquityFieldKey.prevClose]: number;
  [EquityFieldKey.earningsDate]?: string;
  [EquityFieldKey.wkHigh52]: number;
  [EquityFieldKey.wkLow52]: number;
  [EquityFieldKey.callsum]: number;
  [EquityFieldKey.putsum]: number;
  [EquityFieldKey.putCallRatio]: number;
  [EquityFieldKey.minfs]: number;
  [EquityFieldKey.keyGammaStr]: number;
  [EquityFieldKey.keyDeltaStr]: number;
  [EquityFieldKey.hedgeWall]: number;
  [EquityFieldKey.callWall]: number;
  [EquityFieldKey.putWall]: number;
  [EquityFieldKey.largeCoi]: number;
  [EquityFieldKey.largePoi]: number;
  [EquityFieldKey.nextExpGamma]: number;
  [EquityFieldKey.nextExpDelta]: number;
  [EquityFieldKey.topGammaExp]: string;
  [EquityFieldKey.topDeltaExp]: string;
  [EquityFieldKey.putCallVolRatio]: number;
  [EquityFieldKey.callGamma]: number;
  [EquityFieldKey.putGamma]: number;
  [EquityFieldKey.gammaRatio]: number;
  [EquityFieldKey.callDelta]: number;
  [EquityFieldKey.putDelta]: number;
  [EquityFieldKey.putVol]: number;
  [EquityFieldKey.callVol]: number;
  [EquityFieldKey.deltaRatio]: number;
  [EquityFieldKey.totaldelta]: number;
  [EquityFieldKey.d95ne]: number;
  [EquityFieldKey.d25ne]: number;
  [EquityFieldKey.d95]: number;
  [EquityFieldKey.d25]: number;
  [EquityFieldKey.neSkew]: number;
  [EquityFieldKey.skew]: number;
  [EquityFieldKey.nextExpCallVol]: number;
  [EquityFieldKey.nextExpPutVol]: number;
  [EquityFieldKey.optionsImpliedMove]: number;
  [EquityFieldKey.strike_list]: string;
  [EquityFieldKey.cg_list]: string;
  [EquityFieldKey.pg_list]: string;
  [EquityFieldKey.mf_list]: string;
  [EquityFieldKey.smf_list]: string;
  [EquityFieldKey.putctrl]: number;
  [EquityFieldKey.activity_factor]: number;
  [EquityFieldKey.position_factor]: number;
  [EquityFieldKey.call_strikes_list_absg]: string;
  [EquityFieldKey.call_gnot_list_absg]: string;
  [EquityFieldKey.put_strikes_list_absg]: string;
  [EquityFieldKey.put_gnot_list_absg]: string;
  [EquityFieldKey.date]: string;
  [EquityFieldKey.Symbol]: string;
  [EquityFieldKey.volume]: number;
  [EquityFieldKey.dpi]: number;
  [EquityFieldKey.dpi_sector]?: string;
  [EquityFieldKey.rv30]: number;
  [EquityFieldKey.iv30]: number;
  [EquityFieldKey.skewRank]: number;
  [EquityFieldKey.garchRank]: number;
  [EquityFieldKey.ivRank]: number;
  [EquityFieldKey.live]?: boolean;
  [EquityFieldKey.vrp_scanner]?: number;
  [EquityFieldKey.vrp_scanner_high]?: number;
  [EquityFieldKey.squeeze_scanner]?: number;
  [EquityFieldKey.call_oi]?: string;
  [EquityFieldKey.call_gamma_abs]?: string;
  [EquityFieldKey.put_oi]?: string;
  [EquityFieldKey.put_gamma_abs]?: string;
  [EquityFieldKey.iv_pct]?: number;
  [EquityFieldKey.cskew_pct]?: number;
  [EquityFieldKey.pskew_pct]?: number;
}

export type ScannerColumnVisibility = Record<EquityFieldKey, boolean>;

export interface RawDailySkew {
  day: number;
  dte: number;
  exp: number;
  greeks: RawGreeksObject;
}

export interface RawSkewEntry {
  next: RawDailySkew;
  thirty: RawDailySkew;
}

export interface RawSkewObject {
  today: RawSkewEntry;
  yesterday: RawSkewEntry;
}

export interface HistoricalEquity extends Omit<Equity, 'sym' | 'Symbol'> {
  trade_date: string;
}

export enum Scanner {
  MOST_CALL_GAMMA = 'mostCallGamma',
  LOWEST_PUT_CALL_RATIO = 'lowestPutCallRatio',
  GAMMA_SQUEEZE = 'gammaSqueeze',
  MOST_PUT_GAMMA = 'mostPutGamma',
  HIGHEST_PUT_CALL_RATIO = 'highestPutCallRatio',
  CLOSE_TO_HEDGE_WALL = 'closeToHedgeWall',
  TOP_GAMMA_EXP = 'topGammaExp',
  TOP_DELTA_EXP = 'topDeltaExp',
  LARGEST_DELTA = 'largestDelta',
  CLOSE_TO_KEY_DELTA_STRIKE = 'closeToKeyDeltaStrike',
  HIGH_IMPACT = 'highImpact',
  BEARISH_DARK_POOL = 'bearishDarkPool',
  BULLISH_DARK_POOL = 'bullishDarkPool',
  VOL_RISK_PREMIUM = 'volRiskPremium',
  SQUEEZE = 'squeeze',
  CROSS_ASSET_SUMMARY = 'crossAssetSummary',
}

export type GammaImpact = {
  strike?: number;
  call_gnot?: number;
  put_gnot?: number;
  cg?: number;
  pg?: number;
};

export enum EquityColumnGroup {
  STATISTICS = 'STATISTICS',
  SG_KEY_DAILY_LEVELS = 'SG_KEY_DAILY_LEVELS',
  DIRECTIONAL_INDICATORS = 'DIRECTIONAL_INDICATORS',
  VOLATILITY_INSIGHTS = 'VOLATILITY_INSIGHTS',
  DARK_POOL_INDICATORS = 'DARK_POOL_INDICATORS',
}

export interface RawTimeseriesData {
  [symbol: string]: {
    meta: {
      symbol: string;
      interval: string;
      currency: string;
      exchange_timezone: string;
      exchange: string;
      type: string;
    };
    status: 'ok' | 'error';
    values: {
      datetime: string;
      open: string;
      high: string;
      low: string;
      close: string;
      volume: string;
    }[];
    string: string;
  };
}

export interface Tick {
  price: number;
  epoch_millis: number;
}

export type GammaLevel = {
  strike: number;
  next_exp_put?: number;
  next_exp_call?: number;
  current_put?: number;
  current_call?: number;
};

export const TABS: { [key: string]: string } = {
  composite: 'Composite View',
  pc_impact: 'Put & Call Impact',
  live_price: 'Live Price & SG Levels',
  skew: 'Skew',
  history: 'History',
  rr: 'Risk Reversal',
  fixed_strike_matrix: 'Fixed Strike Matrix',
  term_structure: 'Term Structure',
  vol_skew: 'Volatility Skew',
};

export const TAB_KEYS = new Set(Object.keys(TABS));

export type RiskReversal = {
  upx: number;
  epoch_millis: number;
  rr: number;
};

export type RawRiskReversal = {
  upx: number;
  trade_date: string;
  rr: number;
};

const allGridColumnKeys: EquityFieldKey[] = [
  EquityFieldKey.sym,
  EquityFieldKey.price,
  EquityFieldKey.volume,
  EquityFieldKey.earningsDate,
  EquityFieldKey.keyGammaStr,
  EquityFieldKey.keyDeltaStr,
  EquityFieldKey.hedgeWall,
  EquityFieldKey.callWall,
  EquityFieldKey.putWall,
  EquityFieldKey.callGamma,
  EquityFieldKey.putGamma,
  EquityFieldKey.nextExpGamma,
  EquityFieldKey.nextExpDelta,
  EquityFieldKey.topGammaExp,
  EquityFieldKey.topDeltaExp,
  EquityFieldKey.nextExpCallVol,
  EquityFieldKey.nextExpPutVol,
  EquityFieldKey.putCallRatio,
  EquityFieldKey.putCallVolRatio,
  EquityFieldKey.gammaRatio,
  EquityFieldKey.deltaRatio,
  EquityFieldKey.neSkew,
  EquityFieldKey.skew,
  EquityFieldKey.rv30,
  EquityFieldKey.iv30,
  EquityFieldKey.ivRank,
  EquityFieldKey.garchRank,
  EquityFieldKey.optionsImpliedMove,
];

export const ScannerExclusiveColumns: Record<Scanner, EquityFieldKey[]> = {
  [Scanner.SQUEEZE]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.volume,
    EquityFieldKey.earningsDate,
    EquityFieldKey.keyGammaStr,
    EquityFieldKey.keyDeltaStr,
    EquityFieldKey.hedgeWall,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
    EquityFieldKey.putCallRatio,
    EquityFieldKey.putCallVolRatio,
    EquityFieldKey.gammaRatio,
    EquityFieldKey.deltaRatio,
  ],
  [Scanner.VOL_RISK_PREMIUM]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.keyGammaStr,
    EquityFieldKey.keyDeltaStr,
    EquityFieldKey.hedgeWall,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.neSkew,
    EquityFieldKey.skew,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.ivRank,
    EquityFieldKey.garchRank,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.MOST_CALL_GAMMA]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callGamma,
    EquityFieldKey.putGamma,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.nextExpCallVol,
    EquityFieldKey.nextExpPutVol,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.LOWEST_PUT_CALL_RATIO]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callVol,
    EquityFieldKey.putVol,
    EquityFieldKey.nextExpCallVol,
    EquityFieldKey.nextExpPutVol,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.putCallRatio,
    EquityFieldKey.putCallVolRatio,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.MOST_PUT_GAMMA]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callGamma,
    EquityFieldKey.putGamma,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.nextExpCallVol,
    EquityFieldKey.nextExpPutVol,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.HIGHEST_PUT_CALL_RATIO]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callVol,
    EquityFieldKey.putVol,
    EquityFieldKey.nextExpCallVol,
    EquityFieldKey.nextExpPutVol,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.putCallRatio,
    EquityFieldKey.putCallVolRatio,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.optionsImpliedMove,
  ],
  [Scanner.CLOSE_TO_HEDGE_WALL]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.keyGammaStr,
    EquityFieldKey.keyDeltaStr,
    EquityFieldKey.hedgeWall,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.ivRank,
  ],
  [Scanner.TOP_GAMMA_EXP]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.callGamma,
    EquityFieldKey.putGamma,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.nextExpCallVol,
    EquityFieldKey.nextExpPutVol,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
  ],
  [Scanner.TOP_DELTA_EXP]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.callGamma,
    EquityFieldKey.putGamma,
    EquityFieldKey.nextExpGamma,
    EquityFieldKey.nextExpDelta,
    EquityFieldKey.nextExpCallVol,
    EquityFieldKey.nextExpPutVol,
    EquityFieldKey.topGammaExp,
    EquityFieldKey.topDeltaExp,
  ],
  [Scanner.CLOSE_TO_KEY_DELTA_STRIKE]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.earningsDate,
    EquityFieldKey.keyGammaStr,
    EquityFieldKey.keyDeltaStr,
    EquityFieldKey.hedgeWall,
    EquityFieldKey.callWall,
    EquityFieldKey.putWall,
    EquityFieldKey.rv30,
    EquityFieldKey.iv30,
    EquityFieldKey.ivRank,
  ],
  [Scanner.GAMMA_SQUEEZE]: allGridColumnKeys,
  [Scanner.LARGEST_DELTA]: allGridColumnKeys,
  [Scanner.HIGH_IMPACT]: allGridColumnKeys,
  [Scanner.BEARISH_DARK_POOL]: allGridColumnKeys,
  [Scanner.BULLISH_DARK_POOL]: allGridColumnKeys,
  [Scanner.CROSS_ASSET_SUMMARY]: allGridColumnKeys,
};

export const ScannerExclusiveColumnsCondensed: Record<
  Scanner,
  EquityFieldKey[]
> = {
  ...ScannerExclusiveColumns,
  [Scanner.SQUEEZE]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.prevClose,
    EquityFieldKey.volume,
    EquityFieldKey.callGamma,
    EquityFieldKey.callVol,
    EquityFieldKey.topGammaExp,
  ],
  [Scanner.VOL_RISK_PREMIUM]: [
    EquityFieldKey.sym,
    EquityFieldKey.price,
    EquityFieldKey.prevClose,
    EquityFieldKey.volume,
    EquityFieldKey.iv30,
    EquityFieldKey.rv30,
    EquityFieldKey.ivRank,
  ],
};

export const ScannerExclusiveColumnsCondensedWidths: Map<
  EquityFieldKey,
  number
> = new Map([
  [EquityFieldKey.sym, 85],
  [EquityFieldKey.price, 88],
  [EquityFieldKey.prevClose, 95],
  [EquityFieldKey.volume, 90],
  [EquityFieldKey.callGamma, 89],
  [EquityFieldKey.callVol, 88],
  [EquityFieldKey.topGammaExp, 90],
  [EquityFieldKey.iv30, 90],
  [EquityFieldKey.rv30, 90],
  [EquityFieldKey.ivRank, 90],
]);

export enum NewModelPcType {
  OI = 'open_interest',
  ABS_GAMMA = 'absolute_gamma',
  SYNTH_GAMMA = 'synthetic_gamma',
}
