import {
  alpha,
  Stack,
  Typography,
  SelectChangeEvent,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OptionsDropdownSelector from '../../shared/OptionsDropdownSelector';
import StockIcon from 'components/StockIcon';
import { Scanner, Watchlist, Equity, EquityFieldKey } from '../../../types';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { positiveTrendColorState, negativeTrendColorState } from 'states';
import { formatAsCurrency, formatAsPercentage } from 'util/shared';
import { EditWatchlists } from 'components/watchlist/EditWatchlists';
import useToast from 'hooks/useToast';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { SCANNER_NAME_MAPPING } from 'config';

interface WatchlistsAndScannersProps {
  watchlists: Watchlist[];
  scanners: Scanner[];
  selectedWatchlists: string[];
  selectedScanners: Scanner[];
  onWatchlistSelectChange: (
    event: SelectChangeEvent<string | string[]>,
  ) => void;
  onScannerSelectChange: (event: SelectChangeEvent<string | string[]>) => void;
  equities: Equity[];
  onEquityClick: (equity: Equity) => void;
  isExpanded: boolean;
  onToggleExpanded: () => void;
  selectedEquities?: string[];
}

const WatchlistsAndScanners = ({
  watchlists,
  scanners,
  selectedWatchlists,
  selectedScanners,
  onWatchlistSelectChange,
  onScannerSelectChange,
  equities,
  onEquityClick,
  isExpanded,
  onToggleExpanded,
  selectedEquities = [],
}: WatchlistsAndScannersProps) => {
  const theme = useTheme();
  const positiveTrendColor = useRecoilValue(positiveTrendColorState);
  const negativeTrendColor = useRecoilValue(negativeTrendColorState);
  const [showEditWatchlistModal, setShowEditWatchlistModal] = useState(false);

  const { openToast } = useToast();

  const watchlistOptions = useMemo(
    () =>
      watchlists?.map((w) => ({
        label: w.name,
        value: `${w.id!}`,
      })) ?? [],
    [watchlists],
  );

  const scannerOptions = useMemo(
    () => scanners.map((s) => ({ label: SCANNER_NAME_MAPPING[s], value: s })),
    [scanners],
  );

  const sortedEquities = useMemo(() => {
    return [...equities].sort((a, b) => {
      // Selected equities come first
      const aIsSelected = selectedEquities.includes(a.sym);
      const bIsSelected = selectedEquities.includes(b.sym);

      if (aIsSelected && !bIsSelected) return -1;
      if (!aIsSelected && bIsSelected) return 1;

      // If both are selected or both are not selected, maintain original order
      return 0;
    });
  }, [equities, selectedEquities]);

  const onEditWatchlists = () => {
    setShowEditWatchlistModal(true);
  };

  if (!isExpanded) {
    return (
      <Stack
        sx={{
          width: '100%',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Stack
          sx={{
            gap: 2,
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '24px',
          }}
        >
          <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>
            Watchlists and Scanners
          </Typography>
          <IconButton color="primary" onClick={onToggleExpanded}>
            <UnfoldMoreIcon />
          </IconButton>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      sx={{
        gap: 4,
        width: '100%',
        minHeight: '50%',
        height: '100%',
        padding: '24px',
      }}
    >
      <Stack
        sx={{
          gap: 2,
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <OptionsDropdownSelector
          label="Watchlists"
          value={selectedWatchlists}
          options={watchlistOptions}
          isMultiple
          onChange={onWatchlistSelectChange}
          sx={{ maxWidth: '100%' }}
          onEdit={onEditWatchlists}
          isEditable
          editLabel="Edit Watchlists"
        />
        <OptionsDropdownSelector
          label="Scanners"
          value={selectedScanners}
          options={scannerOptions}
          isMultiple
          onChange={onScannerSelectChange}
          sx={{ maxWidth: '100%' }}
        />
      </Stack>

      <Stack
        sx={{
          mt: 2,
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {sortedEquities.length > 0 ? (
          <>
            <Stack
              direction="row"
              sx={{
                px: 2,
                py: 2,
              }}
            >
              <Stack flex={1}>
                <Typography variant="caption" color="text.secondary">
                  SYMBOL
                </Typography>
              </Stack>
              <Stack flex={1} alignItems="right">
                <Typography variant="caption" color="text.secondary">
                  CURRENT PRICE
                </Typography>
              </Stack>
              <Stack flex={1} alignItems="right">
                <Typography variant="caption" color="text.secondary">
                  PRICE CHANGE
                </Typography>
              </Stack>
              <Stack flex={1} alignItems="right">
                <Typography variant="caption" color="text.secondary">
                  SECTOR
                </Typography>
              </Stack>
            </Stack>

            {sortedEquities.map((equity: Equity) => (
              <Stack
                key={equity.sym}
                direction="row"
                sx={{
                  px: 2,
                  py: 2,
                  '&:hover': {
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                  },
                  cursor: 'pointer',
                  alignItems: 'center',
                  borderRadius: '4px',
                  bgcolor: selectedEquities.includes(equity.sym)
                    ? alpha(theme.palette.primary.main, 0.1)
                    : 'inherit',
                }}
                onClick={() => onEquityClick(equity)}
              >
                <Stack flex={1} direction="row" alignItems="center" spacing={3}>
                  <StockIcon symbol={equity.sym} />
                  <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>
                    {equity.sym}
                  </Typography>
                </Stack>
                <Stack flex={1} alignItems="right">
                  <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>
                    {equity.price ? formatAsCurrency(equity.price) : '$-.--'}
                  </Typography>
                </Stack>
                <Stack flex={1} alignItems="right">
                  <Typography
                    sx={{
                      color:
                        equity.price && equity[EquityFieldKey.prevClose]
                          ? equity.price > equity[EquityFieldKey.prevClose]
                            ? positiveTrendColor
                            : equity.price < equity[EquityFieldKey.prevClose]
                            ? negativeTrendColor
                            : 'inherit'
                          : 'inherit',
                      fontSize: { xs: 12, md: 14 },
                    }}
                  >
                    {equity.price && equity[EquityFieldKey.prevClose]
                      ? formatAsPercentage(
                          (equity.price - equity[EquityFieldKey.prevClose]) /
                            equity[EquityFieldKey.prevClose],
                        )
                      : '-.--%'}
                  </Typography>
                </Stack>
                <Stack flex={1} alignItems="right">
                  <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>
                    {equity.dpi_sector ?? 'N/A'}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </>
        ) : (
          <Stack
            sx={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              color: 'text.secondary',
            }}
          >
            <Typography variant="h6">No Equities Found</Typography>
            <Typography variant="body2">
              Select a watchlist or scanner to view equities
            </Typography>
          </Stack>
        )}
      </Stack>

      <EditWatchlists
        onClose={() => setShowEditWatchlistModal(false)}
        open={showEditWatchlistModal}
        modal={true}
        // toast needs to be displayed after the modal disappears
        openToast={(toastData) => setTimeout(() => openToast(toastData), 1000)}
      />
    </Stack>
  );
};

export default WatchlistsAndScanners;
